/**
 * Affiche un message d'alerte
 * @param {{msg: String, type: String, close: Function}} param0
 */
export default function MsgAlert({ msg, type, close = null }) {
  const classError = "alert alert-dismissible animFadeIn alert-" + type;

  return (
    <div className={classError}>
      {close === null ? null : (
        <button type="button" className="close" onClick={close}>
          &times;
        </button>
      )}
      <strong>{msg}</strong>
    </div>
  );
}
