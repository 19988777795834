import React, { useState } from "react";
import { useContext } from "react";
import NotesContext from "../../../Context/NotesContext";
import AppContext from "../../../Context/AppContext";
import { useEffect } from "react";
import GetBddProvider from "../../../Providers/GetBddProvider";
import Spinner from "../../common/Spinner";
import MsgAlert from "../../common/MsgAlert";
import dateFormated from "../../tools/dateFormated";

import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDelete from "../../../hooks/useDelete";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName, triByUserName } from "../../tools/sortUtils";

const ListGestNotes = ({ promos, site }) => {
  const {
    promoLoaded,
    formateur,
    matieres,
    semestreSelected,
    setMaj,
    setGlobalMsg,
    autoCloseGlobal,
    setPromoLoaded,
    setMatieres,
    setMatiere,
    setSemestreSelected,
    setNoteSelected,
    setEleveSelected,
  } = useContext(NotesContext);

  const { url, user } = useContext(AppContext);
  const [msg, setMsg] = useState(null);
  const [mat, setMat] = useState(null);
  const [promo, setPromo] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [notes, setNotes] = useState(null);
  const [semestres, setSemestres] = useState(null);

  const DelBDD = useDelete();

  $(function () {
    $('[data-tooltip="tooltip"]').tooltip();
  });

  useEffect(() => {
    if (promo !== promoLoaded) {
      setLoaded(false);
      setPromo(promoLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoLoaded]);

  useEffect(() => {
    if (promo !== null) {
      if (promo.site["@id"] === site["@id"]) {
        let temp = promo.semestres.filter((sem) => sem.actif);
        setSemestres(promo.semestres);
        setSemestreSelected(
          temp.length > 0
            ? temp[0]
            : promo.semestres[promo.semestres.length - 1]
        );
        load();
      } else {
        init();
        setLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    setMatiere(mat);
    if (semestreSelected !== null && mat !== null) setNotes(null);
    loadNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mat, semestreSelected]);

  const init = () => {
    setNotes(null);
    setMatieres(null);
    setMat(null);
    setEleveSelected(null);
  };

  const loadEleves = () => {
    GetBddProvider(
      url + "api/eleves?user.actif=true&promos=" + promoLoaded["@id"]
    ).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
        setLoaded(true);
      } else {
        setMsg({ txt: "Erreur de chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  const loadNote = () => {
    if (mat !== null && semestreSelected !== null) {
      let uri =
        url +
        "api/notes?semestre=" +
        semestreSelected["@id"] +
        "&matiere=" +
        mat;
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          setNotes(res["hydra:member"]);
        } else {
          setMsg({ txt: "Erreur de chargement des notes", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const delNote = (noteId) => {
    let uri = url + noteId.slice(1);
    DelBDD(uri).then((res) => {
      setLoaded(true);
      if (res === 204) {
        setGlobalMsg({ txt: "La note a bien été supprimée", type: "success" });
        autoCloseGlobal(3000);
        setMaj(true);
      } else {
        setGlobalMsg({ txt: "Erreur de suppression", type: "danger" });
        autoCloseGlobal(3000);
      }
    });
  };

  const load = () => {
    let uri =
      url + "api/matieres?hidden=0&formations=" + promo.formation["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setMatieres(res["hydra:member"].sort(triByName));
        loadEleves();
      } else {
        setMsg({ txt: "Erreur de chargement des matières", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  return (
    <div className="mx-4 ">
      <h3>{site.name}</h3>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <ul className="nav d-flex flex-wrap">
        {promos.map((p, i) => (
          <li className="nav-item" key={i}>
            <span
              className={
                promo !== null && p["@id"] === promo["@id"]
                  ? "btn btn-info"
                  : "btn btn-outline-info"
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                init();
                setPromoLoaded(p);
              }}
            >
              {p.name}
            </span>
          </li>
        ))}
      </ul>
      {!loaded ? (
        <div className="bg-white border-right border-left border-bottom py-1">
          <Spinner />
        </div>
      ) : (
        <div className="bg-white border-right border-left animFadeIn border-bottom p-0 m-0">
          {matieres !== null &&
          semestres !== null &&
          promoLoaded.site["@id"] === site["@id"] ? (
            semestreSelected === undefined ? (
              <div>
                <MsgAlert
                  msg="Les semestres de la promo n'ont pas été activés"
                  type="danger"
                  close={null}
                />
              </div>
            ) : (
              <div>
                <div className="form-group row mx-0 py-2">
                  <label className="col-md-2 col-form-label">Matière</label>
                  <select
                    className="form-control col-md-6"
                    value={mat !== null ? mat : ""}
                    onChange={(e) => {
                      setMat(e.target.value);
                    }}
                  >
                    <option value="" disabled hidden>
                      Sélectionnez une matière
                    </option>
                    {matieres.map((mat, i) => (
                      <option value={mat["@id"]} key={i}>
                        {mat.name}
                      </option>
                    ))}
                  </select>
                  <div className="col-md-4">
                    {semestres.length > 1 ? (
                      <select
                        className="form-control"
                        value={semestreSelected["@id"] || ""}
                        onChange={(e) =>
                          setSemestreSelected(
                            semestres.filter(
                              (sem) => sem["@id"] === e.target.value
                            )[0]
                          )
                        }
                      >
                        <option value="" disabled hidden>
                          Sélectionnez un semestre
                        </option>
                        {semestres.map((s, i) => (
                          <option value={s["@id"]} key={i}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>
                        <strong>
                          <em>{semestreSelected.name}</em>
                        </strong>
                      </span>
                    )}
                  </div>
                </div>

                {eleves.sort(triByUserName).map((el, i) =>
                  !el.user.actif ? null : (
                    <div key={i}>
                      <div className="d-flex text-left">
                        <div className="p-2 col-lg-3 col-xl-2 col-md-4 border-right border-info">
                          {el.user.name} {el.user.firstName}
                        </div>
                        <div className="p-2 col-lg-9 col-xl-10 col-md-8 m-0 h5">
                          {mat === null ? null : notes !== null ? (
                            <>
                              {notes.length < 1 ? (
                                <small>Pas de notes !</small>
                              ) : (
                                notes
                                  .filter(
                                    (n) =>
                                      n.matiere === mat &&
                                      n.semestre === semestreSelected["@id"] &&
                                      n.eleve === el["@id"]
                                  )
                                  .map((n, i) =>
                                    user.role <= 3 ||
                                    (n.formateur["@id"] === formateur["@id"] &&
                                      semestres.filter(
                                        (s) =>
                                          s["@id"] === semestreSelected["@id"]
                                      )[0].actif) ? (
                                      <React.Fragment key={i}>
                                        <span
                                          className={
                                            n.exam &&
                                            n.note !== undefined &&
                                            n.note !== null
                                              ? "badge ml-2 badge-danger"
                                              : n.note !== undefined &&
                                                n.note !== null
                                              ? "badge ml-2 badge-info"
                                              : "badge ml-2 badge-secondary"
                                          }
                                          style={{ cursor: "pointer" }}
                                          data-toggle="modal"
                                          data-target="#ModalModifNote"
                                          onClick={() => {
                                            setEleveSelected(
                                              JSON.parse(JSON.stringify(el))
                                            );
                                            setNoteSelected(
                                              JSON.parse(JSON.stringify(n))
                                            );
                                          }}
                                          data-tooltip="tooltip"
                                          data-html="true"
                                          data-placement="top"
                                          title={`<u><em>${
                                            n.formateur.user.firstName.slice(
                                              0,
                                              1
                                            ) +
                                            n.formateur.user.name.slice(0, 1)
                                          } - ${dateFormated(
                                            n.createdAt
                                          )}</em></u><br> ${n.comment}`}
                                        >
                                          <u>
                                            {n.note !== undefined &&
                                            n.note !== null
                                              ? n.note
                                              : "NE"}
                                          </u>
                                          <sup>
                                            <em>({n.coef})</em>
                                          </sup>
                                        </span>
                                        <sup>
                                          <FontAwesomeIcon
                                            style={{ cursor: "pointer" }}
                                            title="Supprimer"
                                            className="text-danger"
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  "Supprimer la note ?"
                                                )
                                              ) {
                                                delNote(n["@id"]);
                                              }
                                            }}
                                            icon="times-circle"
                                          />
                                        </sup>
                                      </React.Fragment>
                                    ) : (
                                      <span
                                        className={
                                          n.exam
                                            ? "badge ml-2 badge-danger"
                                            : n.note !== undefined &&
                                              n.note !== null
                                            ? "badge ml-2 badge-light"
                                            : "badge ml-2 badge-secondary"
                                        }
                                        data-tooltip="tooltip"
                                        data-html="true"
                                        data-placement="top"
                                        title={`<u><em>${
                                          n.formateur.user.firstName.slice(
                                            0,
                                            1
                                          ) + n.formateur.user.name.slice(0, 1)
                                        } - ${dateFormated(
                                          n.createdAt
                                        )}</em></u><br> ${n.comment}`}
                                        key={i}
                                      >
                                        {n.note !== undefined && n.note !== null
                                          ? n.note
                                          : "NE"}{" "}
                                        <sup>
                                          <em>({n.coef})</em>
                                        </sup>
                                      </span>
                                    )
                                  )
                              )}
                              {user.role <= 3 ||
                              (formateur.matieres.filter(
                                (m) => m["@id"] === mat
                              ).length > 0 &&
                                semestres.filter(
                                  (s) => s["@id"] === semestreSelected["@id"]
                                )[0].actif) ? (
                                <FontAwesomeIcon
                                  className="ml-2 text-primary"
                                  style={{ cursor: "pointer" }}
                                  data-toggle="modal"
                                  data-target="#NoteModal"
                                  onClick={() => {
                                    setEleveSelected(
                                      JSON.parse(JSON.stringify(el))
                                    );
                                  }}
                                  icon="plus-circle"
                                />
                              ) : null}
                            </>
                          ) : (
                            <small>Chargement en cours ...</small>
                          )}
                        </div>
                      </div>
                      {i + 1 < eleves.length ? (
                        <hr className="border-info m-0" />
                      ) : null}
                    </div>
                  )
                )}
              </div>
            )
          ) : null}
        </div>
      )}
      <hr />
    </div>
  );
};

export default ListGestNotes;
