import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormated from "../../tools/dateFormated";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

const ListSmecs = ({ smecs, delSmec, debut, setSmecSelected }) => {
  return (
    <div>
      <h4 className="m-4">Liste des SMEC</h4>
      <div>
        <table
          className="table table-sm table-bordered table-responsive-md"
          id="TableSmec"
        >
          <thead>
            <tr className="bg-info text-white">
              <th>Action</th>
              <th>Date</th>
              <th>Eleve</th>
              <th>Commentaire</th>
              <th>Validé</th>
            </tr>
          </thead>
          <tbody className="text-left">
            {smecs.map((s, i) => {
              return (
                <tr key={i}>
                  <td>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSmecSelected(JSON.parse(JSON.stringify(s)));
                      }}
                      data-toggle="modal"
                      data-target="#ModalViewSuiviSMEC"
                      className="text-info"
                      icon="clipboard-list"
                      title="Visualiser"
                    />{" "}
                    &nbsp;{" "}
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (window.confirm("Supprimer le suivi ?")) {
                          delSmec(s["@id"]);
                        }
                      }}
                      className="text-danger"
                      icon="trash-alt"
                      title="Supprimer"
                    />
                  </td>
                  <td>{dateFormated(s.createdAt)}</td>
                  <td>
                    {s.eleve.user.name} {s.eleve.user.firstName}
                  </td>
                  <td>{s.comment}</td>
                  <td className="text-center">
                    {s.validate ? (
                      <span className="badge badge-pill badge-success m-1 text-white">
                        Oui
                      </span>
                    ) : (
                      <span className="badge badge-pill badge-danger m-1 text-white">
                        Non
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ReactHTMLTableToExcel
          className="btn btn-outline-primary rounded-pill"
          table="TableSmec"
          filename={
            "relevé-smec-" +
            (new Date(debut).getMonth() + 1) +
            "-" +
            new Date(debut).getFullYear()
          }
          sheet="Export"
          buttonText="Export Excel"
        />
      </div>
    </div>
  );
};

export default ListSmecs;
