import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import dateFormated from "../tools/dateFormated";
import EditRetard from "../contents/edits/EditRetard";
import ModifRetard from "../contents/modifs/ModifRetard";
import addTime from "../tools/addTime";
import SyncRetard from "../contents/sync Retard/SyncRetard";
import GestRetardContext from "../../Context/GestRetardContext";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByCreatedAt, triByEleveUserName, triByName, triByUserName } from "../tools/sortUtils";

const GestRetards = () => {
  const { url, user } = useContext(AppContext);

  let temp = new Date().toISOString().split("T")[0];

  const [debut, setDebut] = useState(temp);
  const [fin, setFin] = useState(temp);

  const [loadEdusign, setLoadEdusign] = useState(false);

  const [actif, setActif] = useState(true);
  const [sites, setSites] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [msg, setMsg] = useState(null);
  const [maj, setMaj] = useState(false);
  const [promoSelected, setPromoSelected] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [eleveSelected, setEleveSelected] = useState(null);
  const [elevesloading, setElevesloading] = useState(false);
  const [moment, setMoment] = useState(null);
  const [retards, setRetards] = useState(null);
  const [depart, setDepart] = useState(null);
  const [rtdSelected, setRtdSelected] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [promos, setPromos] = useState(null);
  let totalH = "0:00";

  const contextValue = {
    retards: retards ? retards.filter((el) => !el.depart) : null ,
    departs: retards ? retards.filter((el) => el.depart) : null ,
    setMaj,
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setLoaded(false);
      loadPromos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected, actif]);

  useEffect(() => {
    if (maj === true) {
      setMaj(false);
      loadRetards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    if (loadEdusign) {
      loadRetards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadEdusign]);

  useEffect(() => {
    setEleves(null);
    setEleveSelected(null);
    setElevesloading(true);
    if (promoSelected !== null && promoSelected !== "") {
      loadEleves(promoSelected);
    } else {
      setElevesloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const razData = () => {
    setPromoSelected(null);
    setRetards(null);
    setDepart(null);
  };

  const load = async () => {
    if (user.role > 1) {
      let listSites = user.sites.sort(triByName);
      setSites(listSites);
      if (siteSelected === null) {
        setSiteSelected(listSites[0]["@id"]);
      }
    } else {
      let listSites = user.sites.sort(triByName);
      let uri = url + "api/sites";
      await GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          setSites(res["hydra:member"].sort(triByName));
        } else {
          // Message d'erreur
        }
      });
      if (siteSelected === null) {
        setSiteSelected(listSites[0]["@id"]);
      }
    }
  };

  const loadPromos = () => {
    let uri = `${url}api/promos?site=${siteSelected}`;
    if (actif) {
      uri += "&actif=true";
    }
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
        loadRetards();
      } else {
        setMsg({ txt: "Erreur de chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadRetards = () => {
    let uri =
      url +
      "api/retards?order[createdAt]=asc&createdAt[after]=" +
      debut +
      "&createdAt[before]=" +
      fin;
    if (eleveSelected !== null) {
      uri += "&eleve=" + eleveSelected;
    }
    if (promoSelected === null || promoSelected === "") {
      uri += `&promo.site=${siteSelected}`;
    } else {
      uri += "&promo=" + promoSelected;
    }
    if (moment !== null && moment !== "") {
      uri += "&moment=" + moment;
    }
    if (depart !== null && depart !== "") {
      uri += "&depart=" + depart;
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setRetards(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des retards", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promoSelected;
    GetBddProvider(uri).then((res) => {
      setElevesloading(false);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const filter = (val) => {
    let temp = JSON.parse(JSON.stringify(retards));
    if (val === "date") {
      setRetards(temp.sort(triByCreatedAt));
    } else if (val === "name") {
      setRetards(temp.sort(triByEleveUserName));
    }
    setLoaded(true);
  };

  const delRetard = (iri) => {
    setRetards(null);
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      loadRetards();
      if (res === 204) {
        setMsg({
          txt: "La progression est supprimée !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="m-4">
      <div className="d-flex flex-wrap align-items-center justify-content-md-between justify-content-center mx-4">
        <h2>Retards/départs</h2>
        <div className="form-row align-items-center">
          <div className="my-2">
            <select
              className="form-control rounded-pill"
              value={siteSelected || ""}
              onChange={(e) => {
                razData();
                setSiteSelected(e.target.value);
              }}
            >
              {sites !== null
                ? sites.map((s, i) => (
                    <option value={s["@id"]} key={i}>
                      {s.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="ml-4">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actif || false}
                onChange={(e) => setActif(!actif)}
              />
              <label className="form-check-label">
                <strong>Promos actives</strong>
              </label>
            </div>
          </div>
        </div>
        <button
          className="btn btn-sm rounded-pill btn-info"
          data-toggle="modal"
          data-target="#RetardModal"
        >
          <FontAwesomeIcon icon="edit" /> Saisir un retard / départ
        </button>
      </div>
      <ModifRetard rtdSelected={rtdSelected} maj={setMaj} />
      <EditRetard setMaj={setMaj} promosList={promos} />
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <hr className="m-4" />
      {!loaded || promos === null ? (
        <Spinner />
      ) : (
        <div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-4">
              <label>Promo</label>
              <select
                className="form-control rounded-pill"
                value={promoSelected || ""}
                onChange={(e) => {
                  setPromoSelected(e.target.value);
                }}
              >
                <option value="">Toutes les promos</option>
                {promos.map((p, i) => (
                  <option
                    value={p["@id"]}
                    disabled={p.semestres.length === 0}
                    key={i}
                  >
                    {p.site.name} - {p.name}
                    {p.semestres.length === 0 && " (Pas de semestre)"}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-5">
              <label>Eleves</label>
              <select
                className="form-control rounded-pill"
                value={eleveSelected || ""}
                onChange={(e) => setEleveSelected(e.target.value)}
                disabled={eleves === null}
              >
                <option value="">
                  {elevesloading
                    ? "Chargement en cours ..."
                    : eleves === null
                    ? "Veuillez sélectionner une promo"
                    : eleves.length < 1
                    ? "Pas d'élèves"
                    : "Tous les élèves"}
                </option>
                {eleves === null
                  ? null
                  : eleves.sort(triByUserName).map((el, i) => (
                      <option value={el["@id"]} key={i}>
                        {el.user.name} {el.user.firstName}
                      </option>
                    ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>1/2 journée</label>
              <select
                className="form-control rounded-pill"
                value={moment || ""}
                onChange={(e) => setMoment(e.target.value)}
              >
                <option value="">Matin et Après-midi</option>
                <option value="Matin">Matin</option>
                <option value="Après-midi">Après-midi</option>
              </select>
            </div>
          </div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-4">
              <label>
                Début de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-4">
              <label>
                Fin de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-2">
              <label> Retards/Départs</label>
              <select
                className="form-control rounded-pill"
                value={depart || ""}
                onChange={(e) => setDepart(e.target.value)}
              >
                <option value="">Tous</option>
                <option value="false">Retards</option>
                <option value="true">Départs</option>
              </select>
            </div>
            <div className="form-group col-lg-2 align-self-end">
              <button
                className="btn btn-outline-primary rounded-pill form-control"
                onClick={() => {
                  setRetards(null);
                  loadRetards();
                }}
              >
                Afficher
              </button>
            </div>
          </div>
          {sites.filter((s) => s["@id"] === siteSelected)[0].TokenEdusign ? (
            <button
              className="btn btn-sm rounded-pill btn-outline-info ml-2"
              onClick={() => {
                razData();
                setLoadEdusign(true);
              }}
            >
              Sync Edusign
            </button>
          ) : null}
          <hr className="m-4" />
          {retards === null ? (
            <Spinner />
          ) : (
            <>
              {sites.filter((s) => s["@id"] === siteSelected)[0]
                .TokenEdusign ? (
                <GestRetardContext.Provider value={contextValue}>
                  <SyncRetard
                    site={sites.filter((s) => s["@id"] === siteSelected)[0]}
                    start={debut}
                    end={fin}
                    load={loadEdusign}
                    setLoad={setLoadEdusign}
                  />
                </GestRetardContext.Provider>
              ) : null}
              <div className="m-4">
                <div className="animFadeIn">
                  {retards.length < 1 ? (
                    <h4 className="text-danger m-4">
                      <strong>
                        Pas de retard ou départ anticipé pour la période
                      </strong>
                    </h4>
                  ) : (
                    <div>
                      <div className="d-flex justify-content-between p-4">
                        <h4>Liste des retards et départs anticipés</h4>
                        <ReactHTMLTableToExcel
                          className="btn btn-sm rounded-pill btn-outline-primary"
                          table="TableAbs"
                          filename={
                            "relevé-retards-" +
                            (new Date(debut).getMonth() + 1) +
                            "-" +
                            new Date(debut).getFullYear()
                          }
                          sheet="Export"
                          buttonText="Export Excel"
                        />
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table table-sm table-bordered"
                          id="TableAbs"
                        >
                          <thead>
                            <tr className="bg-info text-white">
                              <th>Sync</th>
                              <th>Action</th>
                              <th>
                                Date{" "}
                                <FontAwesomeIcon
                                  icon="chevron-down"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setLoaded(false);
                                    filter("date");
                                  }}
                                />
                              </th>
                              <th>Promo</th>
                              <th>
                                Elève{" "}
                                <FontAwesomeIcon
                                  icon="chevron-down"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setLoaded(false);
                                    filter("name");
                                  }}
                                />
                              </th>
                              <th className="d-none">Mail Élève</th>
                              <th>Motif</th>
                              <th>Tuteur</th>
                              <th className="d-none">mail</th>
                              <th className="d-none">Mail admin</th>
                              <th className="d-none">Autre e-mail</th>
                              <th>Justificatif</th>
                              <th>Prévenue</th>
                              <th>Heures</th>
                            </tr>
                          </thead>
                          <tbody className="text-left">
                            {retards.map((rtd, i) => {
                              let h = rtd.time.split("T")[1];
                              h = h.split("+")[0];
                              h = h.split(":")[0] + ":" + h.split(":")[1];
                              totalH = addTime(totalH, h);
                              return (
                                <tr key={i}>
                                  <td className="text-center align-middle">
                                    {sites.filter(
                                      (s) => s["@id"] === siteSelected
                                    )[0].TokenEdusign ? (
                                      <>
                                        <FontAwesomeIcon
                                          icon="sync-alt"
                                          className={`${
                                            rtd.edusignId
                                              ? "text-success"
                                              : "text-warning"
                                          }`}
                                          title={
                                            rtd.edusignId
                                              ? "synchronisé"
                                              : "Non synchronisé"
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </td>
                                  <td>
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setRtdSelected(
                                          JSON.parse(JSON.stringify(rtd))
                                        );
                                      }}
                                      data-toggle="modal"
                                      data-target="#ModalModifRtd"
                                      className="text-info"
                                      icon="edit"
                                    />{" "}
                                    &nbsp;{" "}
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Supprimer le retard ?"
                                          )
                                        ) {
                                          delRetard(rtd["@id"]);
                                        }
                                      }}
                                      className="text-danger"
                                      icon="trash-alt"
                                    />
                                  </td>
                                  <td>
                                    {rtd.depart === false ? (
                                      <span className="badge badge-pill badge-danger">
                                        R
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-warning">
                                        D
                                      </span>
                                    )}{" "}
                                    {dateFormated(rtd.createdAt)} - {rtd.moment}
                                  </td>
                                  <td>
                                    {rtd.promo.site.name} - {rtd.promo.name}
                                  </td>
                                  <td>
                                    {rtd.eleve.user.name}{" "}
                                    {rtd.eleve.user.firstName}
                                  </td>
                                  <td className="d-none">
                                    {rtd.eleve.user.email}
                                  </td>
                                  <td>
                                    {rtd.motif === "" || rtd.motif === undefined
                                      ? "Pas de motif"
                                      : rtd.motif}
                                  </td>
                                  {rtd.eleve.entreprises.length < 1 ? (
                                    <React.Fragment>
                                      <td>Pas d'entreprise affectée</td>
                                      <td className="d-none"></td>
                                      <td className="d-none"></td>
                                      <td className="d-none"></td>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <td>
                                        {rtd.eleve.entreprises[0].user.name}{" "}
                                        {
                                          rtd.eleve.entreprises[0].user
                                            .firstName
                                        }{" "}
                                        <em>
                                          (
                                          {
                                            rtd.eleve.entreprises[0]
                                              .raisonSociale
                                          }
                                          )
                                        </em>
                                      </td>
                                      <td className="d-none">
                                        {rtd.eleve.entreprises[0].user.email}
                                      </td>
                                      <td className="d-none">
                                        {rtd.eleve.entreprises[0].mailAdmin}
                                      </td>
                                      <td className="d-none">
                                        {rtd.eleve.entreprises[0].mailAdmin2}
                                      </td>
                                    </React.Fragment>
                                  )}
                                  <td className="text-center">
                                    {rtd.justify === undefined ||
                                    !rtd.justify ? (
                                      <span className="badge badge-pill badge-danger">
                                        Non
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-success">
                                        Oui
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {rtd.prev === undefined || !rtd.prev ? (
                                      <span className="badge badge-pill badge-danger">
                                        Non
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-success">
                                        Oui
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">{h}</td>
                                </tr>
                              );
                            })}
                            <tr className="text-info">
                              <td colSpan="9" className="text-right">
                                <h4>Total :</h4>
                              </td>
                              <td className="d-none"></td>
                              <td className="d-none"></td>
                              <td className="d-none"></td>
                              <td className="d-none"></td>
                              <td className="text-center">
                                <h4>{totalH}</h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GestRetards;
