import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import AppContext from "../../../Context/AppContext";
import useGet from "../../../hooks/useGet";
import SmallSpinner from "../../common/SmallSpinner";

export default function ListExportElevesLine({ eleve }) {
  const { url } = useContext(AppContext);

  const getBdd = useGet();

  const [tuteur, setTuteur] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadTuteur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTuteur = async () => {
    let uriUser = url + eleve.user["@id"].slice(1);
    await getBdd(uriUser).then((res) => {
      if (typeof res === "object") {
        setUser(res);
      } else {
      }
    });
    if (eleve.entreprises.length > 0) {
      let uriTuteur = url + eleve.entreprises[0]["@id"].slice(1);
      await getBdd(uriTuteur).then((res) => {
        if (typeof res === "object") {
          setTuteur(res);
        } else {
        }
      });
    }

    setLoading(false);
  };

  return loading || user === null ? (
    <tr>
      <td colSpan={13}>
        <SmallSpinner />
      </td>
    </tr>
  ) : (
    <tr>
      <td className="font-weight-bold">
        {user.name} {user.firstName}
      </td>
      <td>{user.email}</td>
      {tuteur === null ? (
        <>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary"></td>
          <td className="bg-secondary d-none"></td>
          <td className="bg-secondary d-none"></td>
        </>
      ) : (
        <>
          <td className="font-weight-bold">{tuteur.raisonSociale}</td>
          <td className="font-weight-bold">
            {tuteur.user.name} {tuteur.user.firstName}
          </td>
          <td>{tuteur?.adresse}</td>
          <td>{tuteur?.cp}</td> 
          <td>{tuteur?.ville}</td>
          <td>{tuteur?.tel}</td>
          <td>{tuteur.user.email}</td>
          <td className="d-none">{tuteur.mailAdmin}</td>
          <td className="d-none">{tuteur.mailAdmin2}</td>
        </>
      )}
    </tr>
  );
}
