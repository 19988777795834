import React from "react";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import { NavLink } from "react-router-dom";
import MsgAlert from "../common/MsgAlert";

const Adminitratif = () => {
  const { user } = useContext(AppContext);

  return (
    <div className="mt-4">
      <MsgAlert msg="La campagne de saisie de la SMEC a commencé (Janvier-Février) !" type="danger h3" />
      <h2 className="m-0">
        {user.sites.length === 1
          ? "Mon centre de " + user.sites[0].name
          : "Mes centres de "}
        {user.sites.length > 1
          ? user.sites.map((site, i) => {
              return (
                <span key={i}>
                  {site.name}
                  {+i + 1 < user.sites.length ? " et " : ""}
                </span>
              );
            })
          : ""}
      </h2>
      <hr className="m-4" />
      <div className="d-flex flex-wrap justify-content-center">
        <NavLink to="/eleves" className="col-md-4 p-5 m-4 btn btn-outline-info">
          <h3>Mes étudiants</h3>
        </NavLink>
        <NavLink
          to="/formateurs"
          className="col-md-4 p-5 m-4 btn btn-outline-info"
        >
          <h3>Mes Formateurs</h3>
        </NavLink>
        <NavLink to="/promos" className="col-md-4 p-5 m-4 btn btn-outline-info">
          <h3>Mes Promos</h3>
        </NavLink>
        <NavLink
          to="/progressions"
          className="col-md-4 p-5 m-4 btn btn-outline-info"
        >
          <h3>Progressions pédagogiques</h3>
        </NavLink>
        <NavLink
          to="/releveHeures"
          className="col-md-4 p-5 m-4 btn btn-outline-info"
        >
          <h3>Relevé d'heures</h3>
        </NavLink>
        <NavLink
          to="/absences"
          className="col-md-4 p-5 m-4 btn btn-outline-info"
        >
          <h3>Gestion des absences</h3>
        </NavLink>
      </div>
    </div>
  );
};

export default Adminitratif;
