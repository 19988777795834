import React from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import { useState } from "react";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { useEffect } from "react";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import PostBddProvider from "../../../Providers/PostBddProvider";
import NotesContext from "../../../Context/NotesContext";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditNotesAdmin = () => {
  const { url, user } = useContext(AppContext);
  const { promos, setMaj } = useContext(NotesContext);

  const [matieres, setMatieres] = useState(null);
  const [matiereSelected, setMatiereSelected] = useState(null);
  const [promo, setPromo] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [createdAt, setCreatedAt] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [formateur, setFormateur] = useState(null);
  const [formateurs, setFormateurs] = useState([]);
  const [eleves, setEleves] = useState(null);
  const [semestreSelected, setSemestreSelected] = useState(null);
  const [semestres, setSemestres] = useState(null);
  const [commonComment, setCommonComment] = useState(null);
  const [coef, setCoef] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);
  const [notes, setnotes] = useState([]);
  const [exam, setExam] = useState(false);

  useEffect(() => {
    if (promoSelected !== null) {
      setLoaded(false);
      setPromo(promos.filter((p) => p["@id"] === promoSelected)[0]);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  useEffect(() => {
    if (eleves !== null) {
      setPromo({ ...promo, eleves: eleves });
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eleves]);

  const reset = () => {
    setPromo(null);
    setMatieres(null);
    setFormateur(null);
    setLoaded(false);
    setMatiereSelected(null);
    setExam(false);
    setCommonComment(null);
    setCoef(1);
  };

  const init = () => {
    let tmp = promos.filter((p) => p["@id"] === promoSelected)[0];
    let sem = tmp.semestres.filter((sem) => sem.actif);
    user.role > 3 ? setSemestres(sem) : setSemestres(tmp.semestres);
    setCommonComment(null);
    setCoef(1);
    setSemestreSelected(sem[0] ? sem[0]["@id"] : null);
    setMatiereSelected(null);
    setExam(false);
    loadEleves();
  };

  const load = () => {
    let tmp = promos.filter((p) => p["@id"] === promoSelected)[0];
    let uri = url + "api/matieres?hidden=0&formations=" + tmp.formation["@id"];
    init();
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setMatieres(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des matères", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promoSelected + "&actif=1";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        let tempNotes = {};
        res["hydra:member"].forEach(
          (el) =>
            (tempNotes = {
              ...tempNotes,
              [el["@id"]]: {
                iri: el["@id"],
                name: el.user.name,
                firstName: el.user.firstName,
              },
            })
        );
        setnotes(tempNotes);
        setEleves(res["hydra:member"]);
      } else {
        setLoaded(true);
        setMsg({ txt: "Erreur de chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadFormateurs = (mat) => {
    setFormateur(null);
    setFormateurs([]);
    let uri =
      url + "api/formateurs?promos=" + promo["@id"] + "&matieres=" + mat;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateurs(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des formateurs", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const valid = () => {
    let erreurs = [];
    if (semestreSelected === null) {
      erreurs.push("Veuillez renseigner le semestre");
    }
    if (matiereSelected === null) {
      erreurs.push("Veuillez renseigner la matière");
    }
    if (coef === null) {
      erreurs.push("Veuilez saisir un coefficient");
    }
    if (commonComment === null || commonComment === "") {
      erreurs.push("Veuillez saisir un commentaire");
    }
    if (loaded) {
      let n = null;
      promo.eleves.forEach((el) => {
        if (
          notes[el["@id"]].note !== "" &&
          notes[el["@id"]].note !== undefined
        ) {
          n = true;
        }
      });
      if (n === null) {
        erreurs.push("Veuillez saisir au moins une note");
      }
    }
    if (formateur === null) {
      erreurs.push("Pas de formateur");
    }
    return erreurs.length > 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uri = url + "api/notes";
    let n = 0;
    promo.eleves.forEach(async (el) => {
      let data = {
        eleve: el["@id"],
        matiere: matiereSelected,
        formateur: formateur,
        coef: parseFloat(coef),
        createdAt: createdAt,
        semestre: semestreSelected,
        exam: exam,
      };
      if (notes[el["@id"]].note !== undefined && notes[el["@id"]].note !== "") {
        data = { ...data, note: parseFloat(notes[el["@id"]].note) };
      }
      if (
        notes[el["@id"]].comment !== undefined &&
        notes[el["@id"]].comment !== ""
      ) {
        data = {
          ...data,
          comment: `${commonComment} - ${notes[el["@id"]].comment}`,
        };
      } else if (commonComment !== undefined && commonComment !== "") {
        data = { ...data, comment: commonComment };
      }

      n = await PostBddProvider(uri, data).then((res) => {
        if (typeof res === "object" && res.id) {
          return n + 1;
        } else {
          setLoaded(true);
          setMsg({ txt: "erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
      if (n === promo.eleves.length) {
        reset();
        setMaj(true);
        setMsg({ txt: "les notes ont été enregistrées", type: "success" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalNoteAdmin"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              {promo !== null ? promo.name + " - " : null}
              Saisie des notes
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              {promos === null || notes === null ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={createdAt || ""}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) => setCreatedAt(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Promo</label>
                      <select
                        className="form-control"
                        value={promoSelected || ""}
                        onChange={(e) => setPromoSelected(e.target.value)}
                      >
                        <option value="" disabled>
                          Selectionnez une promo
                        </option>
                        {promos.map((p, i) => (
                          <option
                            value={p["@id"]}
                            disabled={p.semestres.length === 0}
                            key={i}
                          >
                            {p.site.name} - {p.name}
                            {p.semestres.length === 0 && " (Pas de semestre)"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {loaded && matieres !== null ? (
                    <React.Fragment>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Matière</label>
                          <select
                            name="selectMatiere"
                            className={
                              matiereSelected !== null
                                ? "form-control"
                                : "is-invalid form-control"
                            }
                            value={matiereSelected || ""}
                            onChange={(e) => {
                              loadFormateurs(e.target.value);
                              setMatiereSelected(e.target.value);
                            }}
                            required
                          >
                            <option value="" disabled>
                              Selectionnez une matière
                            </option>
                            {matieres.map((mat, i) => (
                              <option value={mat["@id"]} key={i}>
                                {mat.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {semestres.length === 1 ? (
                          <div className="form-group col-md-6">
                            <strong>
                              <em>
                                <u>{semestres[0].name}</u>
                              </em>
                            </strong>
                          </div>
                        ) : (
                          <div className="form-group col-md-6">
                            <label>Semestre</label>
                            <select
                              name="selectSemestre"
                              className="form-control"
                              value={semestreSelected || ""}
                              onChange={(e) =>
                                setSemestreSelected(e.target.value)
                              }
                              required
                            >
                              <option value="" disabled>
                                Sélectionnez un semestre
                              </option>
                              {semestres.map((sem, i) => (
                                <option value={sem["@id"]} key={i}>
                                  {sem.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Formateur</label>
                          <select
                            className="form-control"
                            value={formateur || ""}
                            onChange={(e) => setFormateur(e.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Sélectionner un formateur
                            </option>
                            {formateurs.map((f, i) => (
                              <option value={f["@id"]} key={i}>
                                {f.user.firstName} {f.user.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-4 col-md-3 col-lg-2 mx-auto">
                          <label>Coefficient</label>
                          <input
                            type="number"
                            className={
                              coef !== null && coef !== ""
                                ? "form-control"
                                : "is-invalid form-control"
                            }
                            placeholder="coef"
                            min="0"
                            step="0.01"
                            value={coef || ""}
                            onChange={(e) =>
                              setCoef(parseFloat(e.target.value))
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Commentaire commun</label>
                        <input
                          type="text"
                          className={
                            commonComment !== null && commonComment !== ""
                              ? "form-control"
                              : "is-invalid form-control"
                          }
                          placeholder="Veuillez saisir un commentaire (Obligatoire)"
                          maxLength="250"
                          value={commonComment || ""}
                          onChange={(e) => setCommonComment(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="examCheckbox"
                            checked={exam || false}
                            onChange={(e) => setExam(!exam)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="examCheckbox"
                          >
                            Examen Blanc
                          </label>
                        </div>
                      </div>
                      <hr />
                      {promo.eleves.map((el, i) => {
                        if (el.user.actif) {
                          return (
                            <div
                              className={
                                i + 1 < promo.eleves.length
                                  ? "form-row align-items-center border-bottom border-info text-left"
                                  : "form-row align-items-center text-left"
                              }
                              key={i}
                            >
                              <div className="col-lg-4 d-flex justify-content-center justify-content-lg-start my-3">
                                <div>
                                  <span>
                                    <strong>
                                      {notes[el["@id"]].name}{" "}
                                      {notes[el["@id"]].firstName}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <div className="col-4 col-md-3 col-lg-2 my-2 m-auto">
                                <input
                                  type="number"
                                  className="form-control"
                                  step="0.01"
                                  placeholder="x/20"
                                  value={notes[el["@id"]].note || ""}
                                  min="0"
                                  max="20"
                                  onChange={(e) => {
                                    setnotes({
                                      ...notes,
                                      [el["@id"]]: {
                                        ...notes[el["@id"]],
                                        note: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </div>
                              <div className="flex-grow-1 my-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={notes[el["@id"]].comment || ""}
                                  placeholder="commentaire personnalisé (Facultatif)"
                                  maxLength="250"
                                  onChange={(e) =>
                                    setnotes({
                                      ...notes,
                                      [el["@id"]]: {
                                        ...notes[el["@id"]],
                                        comment: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </React.Fragment>
                  ) : null}
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={valid()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNotesAdmin;
