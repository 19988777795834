import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import Spinner from "../../common/Spinner";
import List from "./List";
import MsgAlert from "../../common/MsgAlert";
import PageFormateurContext from "../../../Context/PageFormateurContext";
import heuresFormH from "../../tools/heuresFormH";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByUserName } from "../../tools/sortUtils";

const ListPromosFormateur = ({
  promos,
  siteId,
  formateurId, // A remplacer par formateur ans le context
  setModalPromo,
}) => {
  const _RETARD = "RETARD";
  const _ABSENCE = "ABSENCE";
  const _MOMENTS = ["Matin", "Après-midi"];
  const { url, user } = useContext(AppContext);
  const {
    setSelectedNotes,
    setSelectedAppreciations,
    setSelectedAbsences,
    setSelectedRetards,
    setModifAbs,
    setModifRtd,
    setPromoSelected,
    delAbsence,
  } = useContext(PageFormateurContext);

  const [loaded, setLoaded] = useState(false);
  const [eleves, setEleves] = useState(null);
  const [formateurs, setFormateurs] = useState(null);
  const [selected, setSelected] = useState(null);
  const [absences, setAbsences] = useState(null);
  const [retards, setRetards] = useState(null);
  const [msg, setMsg] = useState(null);

  const idAccordion = "accordion" + siteId;

  const load = (id) => {
    let uri = url + "api/promos/" + id;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object" && res.id) {
        setSelected(res);

        let uriEl = url + "api/eleves?user.actif=true&promos=" + res["@id"];
        let uriForm = url + "api/formateurs?promos=" + res["@id"];

        let uriAbs =
          url +
          "api/absences?promo=" +
          res["@id"] +
          "&createdAt[after]=" +
          new Date().toISOString().split("T")[0] +
          "&createdAt[before]=" +
          new Date().toISOString().split("T")[0];

        let uriRtd =
          url +
          "api/retards?promo=" +
          res["@id"] +
          "&createdAt[after]=" +
          new Date().toISOString().split("T")[0] +
          "&createdAt[before]=" +
          new Date().toISOString().split("T")[0];

        GetBddProvider(uriEl).then((res) => {
          if (typeof res === "object") {
            setEleves(res["hydra:member"]);
            GetBddProvider(uriForm).then((res) => {
              if (typeof res === "object") {
                setFormateurs(res["hydra:member"].sort(triByUserName));
                GetBddProvider(uriAbs).then((res) => {
                  if (typeof res === "object") {
                    setAbsences(res["hydra:member"]);
                    GetBddProvider(uriRtd).then((res) => {
                      setLoaded(true);
                      if (typeof res === "object") {
                        setRetards(res["hydra:member"]);
                      } else {
                        setMsg({
                          txt: "Erreur de chargement des retards",
                          type: "danger",
                        });
                        autoCloseMsg(setMsg, 5000);
                      }
                    });
                  } else {
                    setLoaded(true);
                    setMsg({
                      txt: "Erreur de chargement des absences",
                      type: "danger",
                    });
                    autoCloseMsg(setMsg, 5000);
                  }
                });
              } else {
                setLoaded(true);
                setMsg({
                  txt: "Erreur de chargement des formateurs",
                  type: "danger",
                });
                autoCloseMsg(setMsg, 5000);
              }
            });
          } else {
            setLoaded(true);
            setMsg({
              txt: "Erreur de chargement des élèves",
              type: "danger",
            });
            autoCloseMsg(setMsg, 5000);
          }
        });
      } else {
        setLoaded(true);
        setMsg({ txt: "Erreur de chargement de la promo", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const ListAbs = ({ datas, type, moments }) => {
    let color = type === _ABSENCE ? "danger" : "warning";
    return (
      <ul className="list-group list-group-flush">
        {moments.map((moment, i) => {
          let datasFiltered = datas.filter((data) => data.moment === moment);
          return (
            <li
              className={`list-group-item ${
                moment === "Matin" && `border-${color}`
              } bg-transparent`}
              key={i}
            >
              <strong>{moment}</strong>
              <ul className="list-group list-group-flush text-left">
                {datasFiltered.length > 0 ? (
                  datasFiltered.map((data, i) => (
                    <li
                      className="d-flex justify-content-between list-group-item bg-transparent"
                      key={i}
                      title={data.motif}
                    >
                      <span>
                        {data.eleve.user.name} {data.eleve.user.firstName} -{" "}
                        {data.motif === undefined || data.motif === ""
                          ? "Pas de motif !"
                          : data.motif}
                        {type === _ABSENCE ? (
                          <em>
                            {data.comment === undefined || data.comment === ""
                              ? ""
                              : ` (${data.comment})`}
                          </em>
                        ) : (
                          <em>{` (${heuresFormH(data.time)})`}</em>
                        )}
                      </span>
                      {user.role <= 3 ||
                      (data.formateur !== undefined &&
                        formateurId === data.formateur["@id"]) ? (
                        <span className="text-nowrap">
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon="edit"
                            onClick={(e) => {
                              setPromoSelected(
                                JSON.parse(JSON.stringify(selected))
                              );
                              type === _ABSENCE
                                ? setModifAbs(JSON.parse(JSON.stringify(data)))
                                : setModifRtd(JSON.parse(JSON.stringify(data)));
                            }}
                            data-toggle="modal"
                            data-target={
                              type === _ABSENCE
                                ? "#ModalModifAbs"
                                : "#ModalModifRtd"
                            }
                          />
                          &nbsp;
                          <FontAwesomeIcon
                            className="text-danger"
                            style={{ cursor: "pointer" }}
                            icon="trash-alt"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  `Supprimer ${
                                    type === _ABSENCE
                                      ? "l'absence"
                                      : "le retard"
                                  }  ?`
                                )
                              ) {
                                type === _ABSENCE
                                  ? delAbsence(
                                      data["@id"],
                                      "l'absence a été supprimée"
                                    )
                                  : delAbsence(
                                      data["@id"],
                                      "le retard a été supprimé"
                                    );
                              }
                            }}
                          />
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </li>
                  ))
                ) : (
                  <li className="list-group-item border-0 bg-transparent">
                    Pas {type === _ABSENCE ? "d'absence" : "de retard"}
                  </li>
                )}
              </ul>
            </li>
          );
        })}
      </ul>
    );
  };

  const Actions = () => {
    return (
      <div className="d-flex flex-wrap justify-content-center">
        <button
          type="button"
          title="Saisir une progression pédagodique"
          className="btn btn-outline-info m-1 col-xl-5 rounded-pill"
          data-toggle="modal"
          data-target="#ModalProg"
          onClick={(e) => setModalPromo(JSON.parse(JSON.stringify(selected)))}
        >
          Saisie des progressions pédagogiques
        </button>
        <button
          type="button"
          title="Saisir des notes"
          className="btn btn-block btn-outline-info m-1 col-xl-5 rounded-pill"
          data-toggle="modal"
          data-target="#NotesModal"
          onClick={(e) => setSelectedNotes({ ...selected, eleves: eleves })}
        >
          Saisie des notes
        </button>
        <button
          type="button"
          title="Saisir les retards"
          className="btn btn-block btn-outline-info m-1 col-xl-5 rounded-pill"
          data-toggle="modal"
          data-target="#RetardModal"
          onClick={(e) => setSelectedRetards({ ...selected, eleves: eleves })}
        >
          Saisie des retards
        </button>
        {
          user.sites.filter((s) => s.id === 16).length === 0 && (
            <button
              type="button"
              title="Saisir les absences"
              className="btn btn-block btn-outline-info m-1 col-xl-5 rounded-pill"
              data-toggle="modal"
              data-target="#AbsenceModal"
              onClick={(e) =>
                setSelectedAbsences({ ...selected, eleves: eleves })
              }
            >
              Saisie des absences
            </button>
          )
        }

        <button
          type="button"
          title="Saisir les appréciations semestrielles"
          className="btn btn-block btn-outline-info m-1 col-xl-5 rounded-pill"
          data-toggle="modal"
          data-target="#CommentMatModal"
          onClick={(e) =>
            setSelectedAppreciations({ ...selected, eleves: eleves })
          }
        >
          Appréciations semestrielles
        </button>
      </div>
    );
  };

  return (
    <div className="accordion animFadeIn" id={idAccordion}>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}

      {promos.map((promo, i) => {
        return (
          <div className="card shadow rounded mx-3" key={i}>
            <div className="card-header bg-info p-0" id={"heading" + promo.id}>
              <h2 className="mb-0">
                <button
                  className="btn btn-block font-weight-bold text-white pb-0"
                  type="button"
                  data-toggle="collapse"
                  data-target={"#collapse" + promo.id}
                  aria-expanded={false}
                  aria-controls={"collapse" + promo.id}
                  onClick={(e) => {
                    setLoaded(false);
                    load(promo.id);
                  }}
                >
                  <h5 className="m-0">
                    {promo.name}{" "}
                    {!promo.actif ? (
                      <em className="text-danger">(inactive)</em>
                    ) : null}
                  </h5>
                  <FontAwesomeIcon icon="chevron-down" />
                </button>
              </h2>
            </div>

            <div
              className="collapse"
              id={"collapse" + promo.id}
              aria-labelledby={"heading" + promo.id}
              data-parent={"#" + idAccordion}
            >
              <div className="card-body">
                {loaded && selected !== null ? (
                  <div>
                    <Actions />
                    <div className="d-flex flex-wrap justify-content-center text-info">
                      <div className="col-xl-5 m-1 border rounded-lg border-danger">
                        <h5 className="mt-3">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon="user-times"
                          />{" "}
                          &nbsp;
                          <u>Absences du jour</u>
                        </h5>
                        {absences === null || absences.length === 0 ? (
                          "pas d'absence"
                        ) : (
                          <ListAbs
                            datas={absences}
                            type={_ABSENCE}
                            moments={_MOMENTS}
                          />
                        )}
                      </div>
                      <div className="col-xl-5 m-1 border rounded-lg border-warning">
                        <h5 className="mt-3">
                          <FontAwesomeIcon
                            className="text-warning"
                            icon="user-clock"
                          />{" "}
                          &nbsp;<u>Retards du jour</u>
                        </h5>

                        {retards === null || retards.length === 0 ? (
                          "pas de retard"
                        ) : (
                          <ListAbs
                            datas={retards}
                            type={_RETARD}
                            moments={_MOMENTS}
                          />
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                      <List
                        datas={eleves.sort(triByUserName)}
                        title="Mes étudiants"
                        nav="eleve"
                        actif="true"
                        promo={promo["@id"]}
                      />
                      <List
                        datas={formateurs}
                        actif="true"
                        title="Formateurs"
                      />
                    </div>
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ListPromosFormateur;
