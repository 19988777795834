import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const SuiviSmec = ({ promo, eleve, tuteur, saisie, setMaj }) => {
  const _EVAL = [
    {
      name: "Subit",
      value: 0,
      icons: ["minus", "minus"],
      color: "danger",
    },
    {
      name: "Exécute",
      value: 1,
      icons: ["minus"],
      color: "warning",
    },
    {
      name: "Maîtrise",
      value: 2,
      icons: ["plus"],
      color: "green-light",
    },
    {
      name: "Maîtrise totale",
      value: 3,
      icons: ["plus", "plus"],
      color: "success",
    },
  ];

  const { url } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [saved, setSaved] = useState(false);
  const [suivi, setSuivi] = useState({
    notes: [],
    eleve: eleve["@id"],
    tuteur: tuteur ? tuteur["@id"] : null,
    promo: promo["@id"],
    comment: null,
  });

  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (promo !== null) {
      loadBlocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  const loadBlocs = () => {
    let uri = url + "api/bloc_afests?formation=" + promo.formation["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const removeCompetence = (comp) => {
    let tmp = [...suivi.notes];
    tmp.forEach((n, i) => {
      if (n.competence["@id"] === comp["@id"]) {
        tmp.splice(i, 1);
      }
    });
    setSuivi({ ...suivi, notes: tmp });
  };

  const addCompetence = (comp, b) => {
    let competence = { ...comp, bloc: b };
    let tmp = [...suivi.notes, { competence: competence }];
    setSuivi({ ...suivi, notes: tmp });
  };

  const editNote = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = null;
    tmp.forEach((n, i) => {
      if (
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
      ) {
        index = i;
      }
    });
    if (index !== null) {
      tmp[index] = { ...tmp[index], note: parseInt(val) };
      setSuivi({ ...suivi, notes: tmp });
    }
  };

  const editComment = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = null;
    tmp.forEach((n, i) => {
      if (
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
      ) {
        index = i;
      }
    });
    if (index !== null) {
      tmp[index] = { ...tmp[index], activities: val };
      setSuivi({ ...suivi, notes: tmp });
    }
  };

  const validate = () => {
    let valid = true;
    suivi.notes.forEach((n) => {
      if (n.note === undefined || n.activities === undefined || n.activities.length < 20) {
        valid = false;
      }
    });
    if (
      suivi.comment === undefined ||
      suivi.comment === null ||
      suivi.comment === ""
    ) {
      valid = false;
    }
    if (suivi.tuteur === undefined || suivi.tuteur === null) {
      valid = false;
    }
    if (saved || !saisie) {
      valid = false;
    }
    if (!loaded) {
      valid = false
    }
    return valid;
  };

  const handleSubmit = async () => {
    let _TODAY = new Date();
    let tmpSuivi = {
      ...suivi,
      name: "SMEC " + dateFormated(_TODAY),
      createdAt: _TODAY,
      validateAt: _TODAY,
      validate: true,
    };
    let tempNotes = [...suivi.notes];
    delete tmpSuivi.notes;

    let uriEval = url + "api/eval_afests";

    let save = await PostBddProvider(uriEval, tmpSuivi);
    let iriEval =
      typeof save === "object" && save.id
        ? "/api/eval_afests/" + save.id
        : null;
    if (iriEval !== null) {
      let erreurs = [];
      tempNotes.forEach(async (n, i) => {
        let note = { ...n, competence: n.competence["@id"], eval: iriEval };
        let saveNote = await PostBddProvider(url + "api/note_afests", note);
        if (typeof saveNote !== "object" || !saveNote.id) {
          erreurs = [...erreurs, n.competence.name];
        }
        if (i + 1 === tempNotes.length) {
          if (erreurs.length > 0) {
            setLoaded(true);
            setMsg({
              txt:
                "Erreur d'enregistrement de " + erreurs.length + " competences",
              type: "danger",
            });
            autoCloseMsg(setMsg, 5000);
          } else {
            setSaved(true);
            setLoaded(true);
            setMsg({
              txt: "L'évaluation a bien été enregistré",
              type: "success",
            });
            autoCloseMsg(setMsg, 5000);
            setMaj(true);
          }
        }
      });
    } else {
      setLoaded(true);
      setMsg({ txt: "erreur d'enregistrement de l'éval", type: "danger" });
      autoCloseMsg(setMsg, 5000);
    }
  };

  const ModalBodySaved = () => {
    return (
      <div className="modal-body">
        {msg !== null && (
          <div className="mb-3">
            <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
          </div>
        )}
        <h3 className="text-info">l'évaluation a bien été saisie !</h3>
      </div>
    );
  };

  return (
    <div
      className="modal fade"
      id="ModalSMEC"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> Suivi Mensuel en Entreprise des
              Compétences
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {saved || !saisie ? (
            <ModalBodySaved />
          ) : (
            <div className="modal-body">
            <MsgAlert msg="Ne sélectionnez que les compétences abordées sur la période." type="danger h4" />
              {msg !== null && (
                <div className="mb-3">
                  <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
                </div>
              )}
              {!loaded ? null : (
                <>
                  <div className="mb-3">
                    <div>
                      <form>
                        <table className="mt-3 table table-sm table-responsive-md">
                          <tbody>
                            {blocs.sort(triByName).map((b, i) => {
                              let notes = suivi.notes.filter(
                                (n) => n.competence.bloc["@id"] === b["@id"]
                              );
                              return (
                                <Fragment key={i}>
                                  <tr>
                                    <th
                                      colSpan="7"
                                      className="bg-info text-white"
                                    >
                                      {b.name}
                                    </th>
                                  </tr>
                                  {b.competences
                                    .sort(triByName)
                                    .map((comp, i) => {
                                      let selected = notes.filter(
                                        (n) =>
                                          n.competence["@id"] === comp["@id"]
                                      );
                                      return (
                                        <Fragment key={i}>
                                          <tr className="text-left">
                                            <th colSpan="7">
                                              <FontAwesomeIcon
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  selected.length > 0
                                                    ? removeCompetence(comp)
                                                    : addCompetence(comp, b);
                                                }}
                                                className={
                                                  selected.length > 0
                                                    ? "text-danger"
                                                    : "text-success"
                                                }
                                                icon={
                                                  selected.length > 0
                                                    ? "arrow-circle-left"
                                                    : "arrow-circle-right"
                                                }
                                                title={
                                                  selected.length > 0
                                                    ? "Retirer la compétence"
                                                    : "Ajouter la compétence"
                                                }
                                              />{" "}
                                              <em
                                                className={
                                                  selected.length > 0
                                                    ? "text-success"
                                                    : null
                                                }
                                              >
                                                - {comp.name}
                                              </em>
                                            </th>
                                          </tr>
                                          {selected.map((n, i) => (
                                            <tr key={i}>
                                              <th></th>
                                              {_EVAL.map((val, i) => (
                                                <th key={i}>
                                                  <div className="form-check form-check-inline">
                                                    <input
                                                      type="radio"
                                                      className={`form-check-input${
                                                        n.note !== undefined &&
                                                        n.note !== null &&
                                                        n.note >= 0
                                                          ? ""
                                                          : " is-invalid"
                                                      }`}
                                                      id={`${val.name}-${comp["@id"]}`}
                                                      name={n.competence["@id"]}
                                                      value={val.value}
                                                      onChange={(e) => {
                                                        editNote(
                                                          e.target.value,
                                                          b["@id"],
                                                          comp["@id"]
                                                        );
                                                      }}
                                                      checked={
                                                        n.note !== undefined
                                                          ? n.note === val.value
                                                          : false
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`${val.name}-${comp["@id"]}`}
                                                    >
                                                      {val.name}
                                                      <span
                                                        className={`text-${val.color} ml-1`}
                                                      >
                                                        {val.icons.map(
                                                          (icon, i) => (
                                                            <FontAwesomeIcon
                                                              icon={icon}
                                                              key={i}
                                                            />
                                                          )
                                                        )}
                                                      </span>
                                                    </label>
                                                  </div>
                                                </th>
                                              ))}
                                              <th className="form-group">
                                                <input
                                                  className={`form-control${
                                                    n.activities !==
                                                      undefined &&
                                                    n.activities !== null &&
                                                    n.activities.length >= 20
                                                      ? ""
                                                      : " is-invalid"
                                                  }`}
                                                  type="text"
                                                  name="commentaire"
                                                  value={n.activities || ""}
                                                  placeholder="Activités réalisées"
                                                  onChange={(e) => {
                                                    editComment(
                                                      e.target.value,
                                                      b["@id"],
                                                      comp["@id"]
                                                    );
                                                  }}
                                                />
                                              </th>
                                            </tr>
                                          ))}
                                        </Fragment>
                                      );
                                    })}
                                </Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </form>
                    </div>
                    <div className="form-group">
                      <label>Commentaires</label>
                      <textarea
                        className={`form-control${
                          suivi.comment !== undefined &&
                          suivi.comment !== null &&
                          suivi.comment !== ""
                            ? ``
                            : ` is-invalid`
                        }`}
                        type="textarea"
                        rows="5"
                        placeholder={"Veuillez saisir un commentaire"}
                        value={suivi.comment || ""}
                        onChange={(e) =>
                          setSuivi({ ...suivi, comment: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                setLoaded(false);
                setMsg({
                  txt: "Enregistremet en cours ...",
                  type: "warning",
                });
                handleSubmit();
              }}
              disabled={!validate()}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuiviSmec;
