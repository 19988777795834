import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import dateFormated from "../../tools/dateFormated";
import PostBddProvider from "../../../Providers/PostBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const SmecBetaModal = ({ promos, setMaj }) => {
  const _EVAL = [
    {
      name: "Subit",
      value: 0,
      icons: ["minus", "minus"],
      color: "danger",
    },
    {
      name: "Exécute",
      value: 1,
      icons: ["minus"],
      color: "warning",
    },
    {
      name: "Maîtrise",
      value: 2,
      icons: ["plus"],
      color: "green-light",
    },
    {
      name: "Maîtrise totale",
      value: 3,
      icons: ["plus", "plus"],
      color: "success",
    },
  ];

  const { url } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [suivi, setSuivi] = useState({
    notes: [],
    tuteur: null,
    eleve: undefined,
    comment: null,
    createdAt: new Date().toISOString().split("T")[0],
  });
  const [promoSelected, setPromoSelected] = useState(null);
  const [saved, setSaved] = useState(false);
  const [eleves, setEleves] = useState([]);
  const [elevesLoading, setElevesLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (promoSelected !== null) {
      setLoaded(false);
      loadBlocs();
      loadEleves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const loadEleves = () => {
    setSuivi((prev) => ({ ...prev, tuteur: null, eleve: undefined }));
    setElevesLoading(true);
    if (promoSelected !== "") {
      let uri = url + "api/eleves?user.actif=1&promos=" + promoSelected;
      GetBddProvider(uri).then((res) => {
        setElevesLoading(false);
        if (typeof res === "object") {
          setEleves(res["hydra:member"]);
        } else {
          setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      setEleves(null);
      setElevesLoading(false);
    }
  };

  const loadBlocs = () => {
    let uri =
      url +
      "api/bloc_afests?formation=" +
      promos.filter((p) => p["@id"] === promoSelected)[0].formation["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const removeCompetence = (comp) => {
    let tmp = [...suivi.notes];
    tmp.forEach((n, i) => {
      if (n.competence["@id"] === comp["@id"]) {
        tmp.splice(i, 1);
      }
    });
    setSuivi({ ...suivi, notes: tmp });
  };

  const addCompetence = (comp, b) => {
    let competence = { ...comp, bloc: b };
    let tmp = [...suivi.notes, { competence: competence }];
    setSuivi({ ...suivi, notes: tmp });
  };

  const validate = () => {
    let valid = true;
    if (suivi.eleve === undefined || suivi.eleve === null) {
      valid = false;
    }
    suivi.notes.forEach((n) => {
      if (n.note === undefined) {
        valid = false;
      } else {
        if (
          n.activities !== undefined &&
          n.activities !== null &&
          n.activities !== "" &&
          n.activities.length < 20
        ) {
          valid = false;
        }
      }
    });
    if (
      suivi.comment === undefined ||
      suivi.comment === null ||
      suivi.comment.length < 20
    ) {
      valid = false;
    }
    if (saved) {
      valid = false;
    }

    return valid;
  };

  const eleveChange = (id) => {
    let eleve = eleves.find((e) => e["@id"] === id);
    let tuteur = eleve.entreprises[0];
    let tuteurId = tuteur ? tuteur["@id"] : null;
    setSuivi((prev) => ({ ...prev, eleve: id, tuteur: tuteurId, notes: [] }));
  };

  const editNote = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = null;
    tmp.forEach((n, i) => {
      if (
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
      ) {
        index = i;
      }
    });
    if (index !== null) {
      tmp[index] = { ...tmp[index], note: parseInt(val) };
      setSuivi((prev) => ({ ...suivi, notes: tmp }));
    }
  };

  const editNoteActivities = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = null;
    tmp.forEach((n, i) => {
      if (
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
      ) {
        index = i;
      }
    });
    if (index !== null) {
      tmp[index] = { ...tmp[index], activities: val };
      setSuivi({ ...suivi, notes: tmp });
    }
  };

  const editNoteComment = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = null;
    tmp.forEach((n, i) => {
      if (
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
      ) {
        index = i;
      }
    });
    if (index !== null) {
      tmp[index] = { ...tmp[index], commentaire: val };
      setSuivi((prev) => ({ ...suivi, notes: tmp }));
    }
  };

  const handleSubmit = async (e, validate = false) => {
    e.preventDefault();
    let _TODAY = new Date();
    let tmpSuivi = {
      ...suivi,
      name: "SMEC " + dateFormated(_TODAY),
      createdAt: _TODAY,
      validateAt: validate ? _TODAY : null,
      promo: promoSelected,
      validate,
    };
    let tempNotes = [...suivi.notes];
    delete tmpSuivi.notes;

    let uriEval = url + "api/eval_afests";

    let save = await PostBddProvider(uriEval, tmpSuivi);
    let iriEval =
      typeof save === "object" && save.id
        ? "/api/eval_afests/" + save.id
        : null;
    if (iriEval !== null) {
      let erreurs = [];
      tempNotes.forEach(async (n, i) => {
        let note = { ...n, competence: n.competence["@id"], eval: iriEval };
        let saveNote = await PostBddProvider(url + "api/note_afests", note);
        if (typeof saveNote !== "object" || !saveNote.id) {
          erreurs = [...erreurs, n.competence.name];
        }
        if (i + 1 === tempNotes.length) {
          if (erreurs.length > 0) {
            setLoaded(true);
            setMsg({
              txt:
                "Erreur d'enregistrement de " + erreurs.length + " competences",
              type: "danger",
            });
            autoCloseMsg(setMsg, 5000);
          } else {
            setSaved(true);
            setLoaded(true);
            setMsg({
              txt: "L'évaluation a bien été enregistré",
              type: "success",
            });
            autoCloseMsg(setMsg, 5000);
            setMaj(true);
          }
        }
      });
    } else {
      setLoaded(true);
      setMsg({ txt: "erreur d'enregistrement de l'éval", type: "danger" });
      autoCloseMsg(setMsg, 5000);
    }
  };

  const ModalBodySaved = () => {
    return (
      <div className="modal-body">
        {msg !== null && (
          <div className="mb-3">
            <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
          </div>
        )}
        <h3 className="text-info">l'évaluation a bien été saisie !</h3>
      </div>
    );
  };

  return (
    <div
      className="modal fade"
      id="ModalSaisiAfest"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> saisie smec
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {saved ? (
            <ModalBodySaved />
          ) : (
            <div className="modal-body">
              <MsgAlert
                msg="Ne sélectionnez que les compétences abordées sur la période."
                type="danger h4"
              />
              <div className="form-row mx-4">
                <div className="form-group col-lg-4">
                  <label>Promo</label>
                  <select
                    className="form-control rounded-pill"
                    value={promoSelected || ""}
                    onChange={(e) => {
                      setPromoSelected(e.target.value);
                    }}
                  >
                    <option value="">
                      {promos.length < 1
                        ? "Pas de promos"
                        : "Selectionnez une promo"}
                    </option>
                    {promos.map((p, i) => (
                      <option value={p["@id"]} key={i}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-lg-4">
                  <label>Elève</label>
                  <select
                    className="form-control  rounded-pill"
                    value={suivi.eleve || ""}
                    onChange={(e) => eleveChange(e.target.value)}
                    disabled={eleves === null}
                  >
                    <option value="">
                      {elevesLoading
                        ? "Chargement en cours ..."
                        : eleves === null
                        ? "Veuillez sélectionner une promo"
                        : eleves.length < 1
                        ? "Pas d'élèves"
                        : "Selectionnez un élève"}
                    </option>
                    {eleves === null
                      ? null
                      : eleves.map((el, i) => (
                          <option value={el["@id"]} key={i}>
                            {el.user.name} {el.user.firstName}
                          </option>
                        ))}
                  </select>
                </div>
                <div className="form-group col-lg-4">
                  <label>
                    Date
                    {navigator.userAgent.indexOf("Safari") >= 1 &&
                    navigator.userAgent.indexOf("Chrome") <= 0 ? (
                      <em>
                        <small> (aaaa-mm-jj)</small>
                      </em>
                    ) : null}
                  </label>
                  <input
                    type="date"
                    className="form-control rounded-pill"
                    placeholder={
                      navigator.userAgent.indexOf("Safari") >= 1 &&
                      navigator.userAgent.indexOf("Chrome") <= 0
                        ? "aaaa-mm-jj"
                        : null
                    }
                    pattern={
                      navigator.userAgent.indexOf("Safari") >= 1 &&
                      navigator.userAgent.indexOf("Chrome") <= 0
                        ? "\\d{4}-\\d{2}-\\d{2}"
                        : null
                    }
                    value={suivi.createdAt || ""}
                    onChange={(e) => {
                      setSuivi({
                        ...suivi,
                        createdAt: e.target.value,
                      });
                    }}
                    required
                  />
                </div>
              </div>

              {!loaded ? null : (
                <div className="mb-3">
                  {msg !== null ? (
                    <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
                  ) : null}

                  <div>
                    <form>
                      <table className="mt-3 table table-sm table-responsive-md">
                        <tbody>
                          {blocs.sort(triByName).map((b, i) => {
                            let notes = suivi.notes.filter(
                              (n) => n.competence.bloc["@id"] === b["@id"]
                            );
                            return (
                              <Fragment key={i}>
                                <tr>
                                  <th
                                    colSpan="7"
                                    className="bg-info text-white"
                                  >
                                    {b.name}
                                  </th>
                                </tr>
                                {b.competences
                                  .sort(triByName)
                                  .map((comp, i) => {
                                    let selected = notes.filter(
                                      (n) => n.competence["@id"] === comp["@id"]
                                    );
                                    return (
                                      <Fragment key={i}>
                                        <tr className="text-left">
                                          <th colSpan="7">
                                            <FontAwesomeIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                selected.length > 0
                                                  ? window.confirm(
                                                      "Voulez-vous vraiment retirer cette compétence ?"
                                                    ) && removeCompetence(comp)
                                                  : addCompetence(comp, b);
                                              }}
                                              className={
                                                selected.length > 0
                                                  ? "text-danger"
                                                  : "text-success"
                                              }
                                              icon={
                                                selected.length > 0
                                                  ? "arrow-circle-left"
                                                  : "arrow-circle-right"
                                              }
                                              title={
                                                selected.length > 0
                                                  ? "Retirer la compétence"
                                                  : "Ajouter la compétence"
                                              }
                                            />{" "}
                                            <em
                                              className={
                                                selected.length > 0
                                                  ? "text-success"
                                                  : null
                                              }
                                            >
                                              - {comp.name}
                                            </em>
                                          </th>
                                        </tr>
                                        {selected.map((n, i) => {
                                          return (
                                            <Fragment key={i}>
                                              {(n.note === undefined ||
                                                n.note === null) && (
                                                <tr>
                                                  <th
                                                    colSpan={4}
                                                    className=" border-0 p-0 m-0"
                                                  >
                                                    <small className="text-danger">
                                                      <strong>
                                                        Notation obligatoire.
                                                      </strong>
                                                    </small>
                                                  </th>
                                                </tr>
                                              )}
                                              <tr>
                                                {_EVAL.map((val, i) => {
                                                  return (
                                                    <th
                                                      className="border-0 align-middle"
                                                      key={i}
                                                    >
                                                      <div className="form-check form-check-inline">
                                                        <input
                                                          type="radio"
                                                          className={`form-check-input ${
                                                            n.note !==
                                                              undefined &&
                                                            n.note !== null &&
                                                            n.note >= 0
                                                              ? ""
                                                              : "is-invalid"
                                                          }`}
                                                          id={`${val.name}-${comp["@id"]}`}
                                                          aria-describedby={`notation${comp["@id"]}Feedback`}
                                                          name={
                                                            n.competence["@id"]
                                                          }
                                                          value={val.value}
                                                          onChange={(e) => {
                                                            editNote(
                                                              e.target.value,
                                                              b["@id"],
                                                              comp["@id"]
                                                            );
                                                          }}
                                                          checked={
                                                            n.note !==
                                                              undefined &&
                                                            n.note === val.value
                                                          }
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor={`${val.name}-${comp["@id"]}`}
                                                        >
                                                          {val.name}{" "}
                                                          <span
                                                            className={`text-${val.color}`}
                                                          >
                                                            {val.icons.map(
                                                              (icon, i) => (
                                                                <FontAwesomeIcon
                                                                  icon={icon}
                                                                  key={i}
                                                                />
                                                              )
                                                            )}
                                                          </span>
                                                        </label>
                                                      </div>
                                                    </th>
                                                  );
                                                })}
                                                <th className="form-group border-0">
                                                  <input
                                                    className={`form-control ${
                                                      n.activities !==
                                                        undefined &&
                                                      n.activities !== null &&
                                                      n.activities !== "" &&
                                                      n.activities.length < 20
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    type="text"
                                                    name={`activities${comp["@id"]}`}
                                                    aria-describedby={`activities${comp["@id"]}Feedback`}
                                                    value={n.activities || ""}
                                                    placeholder="Activités réalisées"
                                                    onChange={(e) =>
                                                      editNoteActivities(
                                                        e.target.value,
                                                        b["@id"],
                                                        comp["@id"]
                                                      )
                                                    }
                                                  />
                                                  <div
                                                    id={`activities${comp["@id"]}Feedback`}
                                                    className="invalid-feedback"
                                                  >
                                                    20 caractères minimum.
                                                  </div>
                                                </th>
                                              </tr>
                                              <tr>
                                                <th
                                                  colSpan={5}
                                                  className="border-0"
                                                >
                                                  <input
                                                    className="form-control"
                                                    type="text"
                                                    name="commentaire_pedago"
                                                    value={n.commentaire || ""}
                                                    placeholder="Commentaire pedagogique"
                                                    onChange={(e) =>
                                                      editNoteComment(
                                                        e.target.value,
                                                        b["@id"],
                                                        comp["@id"]
                                                      )
                                                    }
                                                  />
                                                </th>
                                              </tr>
                                            </Fragment>
                                          );
                                        })}
                                      </Fragment>
                                    );
                                  })}
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </form>
                  </div>
                  <div className="form-group">
                    <label>Commentaires</label>
                    <textarea
                      className={`form-control ${
                        suivi.comment !==
                          undefined &&
                          suivi.comment !== null &&
                          suivi.comment !== "" &&
                          suivi.comment.length < 20
                          ? "is-invalid"
                          : ""
                      }`}
                      name="commentaire"
                      type="textarea"
                      rows="5"
                      aria-describedby="commentaireGlobalFeedback"
                      placeholder={"Veuillez saisir un commentaire"}
                      value={suivi.comment || ""}
                      onChange={(e) =>
                        setSuivi({ ...suivi, comment: e.target.value })
                      }
                    />
                    <div
                      id="commentaireGlobalFeedback"
                      className="invalid-feedback"
                    >
                      20 caractères minimum.
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-success"
              onClick={(e) => handleSubmit(e, true)}
              disabled={!validate()}
            >
              CoValidation
            </button>
            {!suivi.validate ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={(e) => handleSubmit(e)}
                disabled={!validate()}
              >
                Valider
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmecBetaModal;
