/**
 * Gére la fermeture des messages d'alerte
 * 
 * @param {()=>void} setMsg 
 * @param {number} time 
 * @return {void}
 */
export const autoCloseMsg = (setMsg, time = 0) => {
  console.log(setMsg, time)
  setTimeout(() => {
    setMsg(null);
  }, time);
};