import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import EleveItemGalia from "./EleveItemGalia";
import { triByStagiaireNom, triByUserName } from "../../tools/sortUtils";

const SyncElevesGalia = ({ promo, actions, action, site }) => {
  const { url, galiaUser } = useContext(AppContext);

  const [eleves, setEleves] = useState([]);
  const [elevesGalia, setElevesGalia] = useState([]);

  const [maj, setMaj] = useState(false);

  useEffect(() => {
    if (promo !== null) {
      setEleves(null);
      setElevesGalia([]);
      loadEleves();
      loadElevesGalia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    if (maj) {
      loadEleves();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promo["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        // message d'erreur
      }
    });
  };

  const loadElevesGalia = () => {
    let uri = `https://groupe-alternance.sc-form.net/GaliaEDC/API/Entite/GetEntite?IDSociete=${site.idGaliaSociete}&IDACTION=${action.ID}&ACTION=INSCRIT`;
    GetBddProvider(uri, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        setElevesGalia(res);
      } else {
        // message d'erreur
      }
    });
  };

  return (
    <div className="card text-left" style={{ minHeight: "100%" }}>
      <div className="card-header">
        <h5 className="card-title text-center">
          Synchronisation des élèves {promo ? promo.name : null}
        </h5>
      </div>
      <div className="card-body p-0">
        {!promo || !action ? (
          <MsgAlert
            msg="Veuillez selectionner une promo synchronisée"
            type="info"
          />
        ) : !eleves || !elevesGalia ? (
          <div className="text-center text-info">
            <SmallSpinner />
          </div>
        ) : (
          <ul className="list-group list-group-flush">
            {elevesGalia.sort(triByStagiaireNom).map((student) => (
              <EleveItemGalia
                promo={promo}
                listIdGalia={elevesGalia.map((stud) => stud.ID)}
                site={site}
                student={student}
                elevesList={eleves.sort(triByUserName)}
                maj={setMaj}
                key={student.ID}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SyncElevesGalia;
