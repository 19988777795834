import "react-big-calendar/lib/css/react-big-calendar.css";
import React from "react";
import Layout from "../common/Layout";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Fragment, useState, useEffect, useContext } from "react";
import moment from "moment";
import AppContext from "../../Context/AppContext";
import useGet from "../../hooks/useGet";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import Deploiement from "./Deploiement";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triById, triByLibelle_ActionDESC, triByName, triByUserName } from "../tools/sortUtils";

require("moment/locale/fr.js");

export default function Planning() {
  const { user, url, galiaUser } = useContext(AppContext);

  const _DEFAULT_VIEW = user.role <= 3 ? "month" : "work_week";
  const _EXCLUDE_VIEW_DESC = ["month", "week"];
  const _VIEWS =
    user.role <= 3
      ? ["month", "work_week", "day", "agenda"]
      : ["month", "work_week", "agenda"];
  const _TODAY = new Date();
  const _SITES = user.sites.filter((s) => s.idGaliaSociete);

  const GetBDD = useGet();

  const localizer = momentLocalizer(moment);
  const messages = {
    allDay: "journée",
    previous: "<",
    next: ">",
    today: "aujourd'hui",
    month: "mois",
    week: "semaine",
    work_week: "semaine",
    day: "journée",
    agenda: "Agenda",
    date: "date",
    time: "heure",
    event: "événement",
    noEventsInRange: "Pas d'évènements sur la période",
    showMore: (total) => `+ ${total} événement(s) supplémentaire(s)`,
  };

  const [date, setDate] = useState(_TODAY);

  const [dateDebut, setDateDebut] = useState(
    new Date(_TODAY.getFullYear(), _TODAY.getMonth() - 1, 1)
  );
  const [dateFin, setDateFin] = useState(
    new Date(_TODAY.getFullYear(), _TODAY.getMonth() + 3, 0)
  );

  const [sites, setSites] = useState(_SITES);
  const [siteSelected, setSiteSelected] = useState(
    _SITES.length > 0 ? _SITES.sort(triById)[0]["@id"] : null
  );

  const [eventlist, setEventlist] = useState([]);
  const [myEventsList, setMyEventsList] = useState([]);
  const [formateurs, setFormateurs] = useState([]);
  const [formateur, setFormateur] = useState(null);
  const [msg, setMsg] = useState(null);
  const [maj, setMaj] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [promos, setPromos] = useState([]);
  const [promo, setPromo] = useState(null);
  const [view, setView] = useState(_DEFAULT_VIEW);

  useEffect(() => {
    if (user.role === 1) {
      loadSites();
    }
    if (user.role <= 3) {
      loadFormateurs();
      loadPromos();
    }
    if (user.role > 3 && user.role <= 4) {
      getFormateur();
    }
    if (user.role === 5) {
      getPromoEleve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loaded) {
      setFormateur(null);
      setPromo(null);
      if (user.role < 5) {
        loadPromos();
      }
      loadFormateurs();
      if (view === "day") {
        fullEvents(eventlist);
      }
      setMaj(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (maj) {
      GetEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    if (view !== "day") {
      promo !== null ? filtrePromoEvents(eventlist) : setMyEventsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    if (formateur !== null && view !== "day") {
      filtreFormteurEvent(eventlist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formateur]);

  const getPromoEleve = () => {
    let uri = url + "api/promos?site=" + siteSelected;
    GetBDD(uri).then((res) => {
      if (typeof res === "object") {
        let result = res["hydra:member"].filter((p) => p.actif);
        let uriEleve = url + "api/eleves?user=" + user["@id"];
        GetBDD(uriEleve).then((res2) => {
          if (typeof res2 === "object") {
            let tmp = res2["hydra:member"];
            if (tmp.length > 0) {
              let eleve = tmp[0];
              let tmpPromos = [];
              result.forEach((r) => {
                if (eleve.promos.includes(r["@id"])) {
                  tmpPromos = [...tmpPromos, r];
                }
              });
              if (tmpPromos.length > 0) {
                setPromos(tmpPromos.sort(triByName));
                setPromo(tmpPromos[0].idGalia);
                !loaded && setMaj(true);
              } else {
                setMsg({
                  txt: "Non Synchronisé avec Galia",
                  type: "danger",
                });
                autoCloseMsg(setMsg, 5000);
              }
            }
          } else {
            setMsg({
              txt: "Erreur de chargement",
              type: "danger",
            });
            autoCloseMsg(setMsg, 5000);
          }
        });
      } else {
        setMsg({
          txt: "Erreur de chargement des Promos",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const getFormateur = () => {
    GetBDD(url + "api/formateurs?user=" + user["@id"]).then((res) => {
      if (typeof res === "object") {
        let result = res["hydra:member"].filter((f) => f.idGalia);
        if (result.length > 0) {
          setFormateurs(result.sort(triByUserName));
          setFormateur(result[0].idGalia);
          !loaded && setMaj(true);
        } else {
          !loaded && setMaj(true);
          setMsg({
            txt: "Non Synchronisé avec Galia",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      } else {
        setMsg({
          txt: "Erreur de chargement !",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadFormateurs = () => {
    let uri = url + "api/formateurs?user.actif=1&user.sites=" + siteSelected;
    GetBDD(uri).then((res) => {
      if (typeof res === "object") {
        setFormateurs(
          res["hydra:member"].filter((f) => f.idGalia).sort(triByUserName)
        );
      } else {
        setMsg({
          txt: "Erreur de chargement des formateurs",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadPromos = () => {
    let uri = url + "api/promos?actif=1&site=" + siteSelected;
    GetBDD(uri).then((res) => {
      if (typeof res === "object") {
        let tmp = res["hydra:member"]
          .filter((p) => p.idGalia)
          .sort(triByName);
        setPromos(tmp);
        if (tmp.length > 0) {
          setPromo(tmp[0].idGalia);
          !loaded && setMaj(true);
        } else {
          !loaded && setMaj(true);
        }
      } else {
        setMsg({
          txt: "Erreur de chargement des Promos",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadSites = () => {
    let uri = url + "api/sites";
    GetBDD(uri).then((res) => {
      if (typeof res === "object") {
        setSites(
          res["hydra:member"]
            .filter((s) => s.idGaliaSociete)
            .sort(triByName)
        );
      } else {
        setMsg({
          txt: "Erreur de chargement des sites",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const GetEvents = async () => {
    let societe = sites.filter((s) => s["@id"] === siteSelected)[0]
      .idGaliaSociete;

    let urlGalia = `https://groupe-alternance.sc-form.net/GaliaEDC/API/PlanningIntervenant/GetPlanning?IDSociete=${societe}&Debut=${dateDebut.getFullYear()}-${
      dateDebut.getMonth() + 1
    }-${dateDebut.getDate()}&Fin=${dateFin.getFullYear()}-${
      dateFin.getMonth() + 1
    }-${dateFin.getDate()}`;

    let json = [];
    await GetBDD(urlGalia, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        json = res;
      } else {
        // message d'erreur
      }
    });

    setEventlist(json);
    if (promo !== null) {
      filtrePromoEvents(json);
    }
    if (formateur !== null) {
      filtreFormteurEvent(json);
    }
    if (view === "day") {
      fullEvents(json);
    }
    if (!loaded) {
      setLoaded(true);
    }
    setMaj(false);
  };

  const filtreFormteurEvent = (events) => {
    let tmp = [];
    events
      .filter((e) => e.IDIntervenant === formateur)
      .forEach((e) => {
        tmp = [...tmp, ...e.SeancesIntervenant];
      });
    setMyEventsList(tmp);
  };

  const filtrePromoEvents = (events, events2) => {
    let tmp = [];
    events.forEach(
      (e) =>
        (tmp = [
          ...tmp,
          ...e.SeancesIntervenant.filter((s) => s.IDAction === promo),
        ])
    );
    setMyEventsList(tmp);
  };

  const fullEvents = (events) => {
    let tmp = [];
    let site = sites.filter((s) => s["@id"] === siteSelected)[0];
    events.forEach((e) => {
      let datas = site.idGaliaLieu
        ? e.SeancesIntervenant.filter((s) => s.IDLieu === site.idGaliaLieu)
        : e.SeancesIntervenant;
      tmp = [...tmp, ...datas];
    });
    setMyEventsList(tmp);
  };

  const handleSelect = (prop) => {
    const title = window.prompt("New Event name");
    if (title)
      setMyEventsList([
        ...myEventsList,
        {
          start: prop.start,
          end: prop.end,
          title,
        },
      ]);
  };

  /** Selection d'un evenement */
  const viewSelect = (prop) => {
    if (view === "month") {
      setDate(new Date(prop.Date));
      setView("work_week");
    }
  };

  const eventGetter = (event, start, end, isSelected) => {
    let past =
      new Date(event.Date) <
      new Date(_TODAY.getFullYear(), _TODAY.getMonth(), _TODAY.getDate());
    if (view !== "agenda") {
      let newStyle = {
        backgroundColor: past ? "lightgrey" : event.Couleur || "white",
        color: "black",
        border: `${past ? "3px" : "1px"} solid ${
          past ? event.Couleur || "black" : "black"
        }`,
      };
      return {
        className: "",
        style: newStyle,
      };
    }
  };

  const Events = ({ event }) => {
    let H = event.Debut.split(":")[0];
    let M = event.Debut.split(":")[1];
    let start = H + ":" + M;
    let libelle = "";
    let title = "";

    let past =
      new Date(event.Date) <
      new Date(_TODAY.getFullYear(), _TODAY.getMonth(), _TODAY.getDate());

    if (formateur !== null || view === "day") {
      libelle += event.Libelle_ACTION || event.Libelle_Action;
    }
    if (promo !== null && view !== "day") {
      libelle += event.Libelle_MODULE || event.Libelle_Court;
      event.Intervenants.forEach((I, i, tab) => {
        title += I.Civilite + " " + I.Nom + " " + I.Prenom;
        if (tab.length > i + 1) {
          title += " - ";
        }
      });
      if (event.Salles && event.Salles.length > 0) {
        title += " (";
        event.Salles.forEach((S, i, tab) => {
          title += S.Libelle_Court;
          if (i + 1 < tab.length) {
            title += " ";
          }
        });
        title += ")";
      }
    }

    return (
      <div
        title={
          title !== "" ? title : event.Libelle_MODULE || event.Libelle_Court
        }
        className={`p-0 ${
          view === "month" ? " text-left font-small" : "text-left"
        }`}
      >
        <div className="my-0 d-flex align-items-center p-0">
          {past && event.Couleur && (
            <span
              className={`d-none d-lg-block badge badge-pill border border-dark mr-1${
                view === "month" ? " p-1" : ""
              }`}
              style={{ background: event.Couleur, color: event.Couleur }}
            >
              {view !== "month" && "I"}
            </span>
          )}
          {view === "month" && (
            <small className="d-none d-xl-block mr-1">{start} -</small>
          )}

          <small className="font-weight-bold">
            {libelle}{" "}
            {(formateur !== null || view === "day") &&
              " - " + event.Libelle_Lieu}
          </small>
        </div>
        {event.Salles &&
          event.Salles.length > 0 &&
          // !_EXCLUDE_VIEW_DESC.includes(view) &&
          event.Salles.map((S, i) => (
            <Fragment key={i}>
              <em>
                <small className="font-weight-bold">
                  {view !== "month" || formateur !== null
                    ? `Salle : ${S.Libelle_Court}`
                    : `(${S.Libelle_Court})`}
                </small>
              </em>
              {view !== "month" && <br />}
            </Fragment>
          ))}
        {(formateur !== null || view === "day") &&
          !_EXCLUDE_VIEW_DESC.includes(view) && (
            <small>{event.Libelle_MODULE}</small>
          )}
        {(formateur === null || view === "day") &&
          event.Intervenants &&
          // !_EXCLUDE_VIEW_DESC.includes(view) &&
          event.Intervenants.map((I, i) => (
            <small key={i}>
              {" " + I.Civilite + " " + I.Nom + " " + I.Prenom}
            </small>
          ))}
      </div>
    );
  };

  /* changement de date */
  const onNavigate = (prop) => {
    if (prop.getMonth() !== date.getMonth()) {
      setDate(prop);
      //Attention changement d'année
      setDateDebut(new Date(prop.getFullYear(), prop.getMonth() - 1, 1));
      setDateFin(new Date(prop.getFullYear(), prop.getMonth() + 3, 0));
      setMaj(true);
    } else {
      setDate(prop);
    }
  };

  /* */
  const onView = (prop) => {
    setView(prop);
    if (view === "day" && promo !== null) {
      filtrePromoEvents(eventlist);
    }
    if (view === "day" && formateur !== null) {
      filtreFormteurEvent(eventlist);
    }
    if (prop === "day") {
      fullEvents(eventlist);
    }
  };

  return Layout(
    user.role > 5 ? (
      <Deploiement />
    ) : !loaded ? (
      <Spinner />
    ) : (
      <div className="mx-lg-4">
        <div className="d-flex justify-content-between">
          <h2>Planning</h2>
          {user.role <= 3 && (
            <div className="form-group my-2">
              <select
                className="form-control form-control-sm rounded-pill"
                value={siteSelected || ""}
                onChange={(e) => {
                  setSiteSelected(e.target.value);
                }}
              >
                {sites.map((s, i) => (
                  <option value={s["@id"]} key={i}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <hr />
        {user.role <= 3 && (
          <>
            <div className="form-inline flex-column flex-lg-row justify-content-lg-start justify-content-center">
              <div className="form-group mb-0">
                <label className="mr-2">Promo : </label>
                {promos.length > 0 ? (
                  <select
                    className="form-control form-control-sm rounded-pill"
                    value={promo || ""}
                    onChange={(e) => {
                      setPromo(parseInt(e.target.value));
                      setFormateur(null);
                    }}
                    disabled={view === "day"}
                  >
                    <option value="" disabled>
                      Promo
                    </option>
                    {promos
                      .filter((p) => p.idGalia)
                      .map((p) => (
                        <option value={p.idGalia} key={p["@id"]}>
                          {p.name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <span className="text-danger">Pas de promos synchronisé</span>
                )}
              </div>
              <div className="form-group ml-lg-4 mb-0 mt-2 mt-lg-0">
                <label className="mr-2">Formateur : </label>
                {formateurs.length > 0 ? (
                  <select
                    className="form-control form-control-sm rounded-pill"
                    value={formateur || ""}
                    onChange={(e) => {
                      setFormateur(parseInt(e.target.value));
                      setPromo(null);
                    }}
                    disabled={view === "day"}
                  >
                    <option value="" disabled>
                      Formateur
                    </option>
                    {formateurs
                      .filter((f) => f.idGalia)
                      .map((f) => (
                        <option value={f.idGalia} key={f["@id"]}>
                          {f.user.name} {f.user.firstName}
                        </option>
                      ))}
                  </select>
                ) : (
                  <span className="text-danger">
                    Pas de formateurs synchronisé
                  </span>
                )}
              </div>
            </div>
            <hr />
          </>
        )}
        {msg !== null && (
          <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
        )}
        {maj ? (
          <Spinner />
        ) : (
          <Calendar
            date={date}
            // selectable
            drilldownView="work_week"
            onView={onView}
            min={new Date(0, 0, 0, 7, 0, 0)}
            max={new Date(0, 0, 0, 19, 0, 0)}
            onNavigate={onNavigate}
            messages={messages}
            defaultView={_DEFAULT_VIEW}
            views={_VIEWS}
            view={view}
            localizer={localizer}
            events={myEventsList.sort(triByLibelle_ActionDESC)}
            components={{ event: Events }}
            startAccessor={(event) => {
              return new Date(event.Date.split("T")[0] + "T" + event.Debut);
            }}
            endAccessor={(event) => {
              return new Date(event.Date.split("T")[0] + "T" + event.Fin);
            }}
            titleAccessor={(event) => {
              return event.Libelle_ACTION + " - " + event.Libelle_MODULE;
            }}
            culture="fr-FR"
            style={{ minHeight: view === "month" && "800px" }}
            onSelectEvent={viewSelect}
            onSelectSlot={handleSelect}
            eventPropGetter={eventGetter}
          />
        )}
      </div>
    )
  );
}
