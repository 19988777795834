import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByCompetenceName } from "../../tools/sortUtils";

const ViewSuiviAfest = ({ suiviSelected }) => {
  const _EVAL = [
    {
      name: "Subit",
      value: 0,
      icons: ["minus", "minus"],
      color: "danger",
    },
    {
      name: "Exécute",
      value: 1,
      icons: ["minus"],
      color: "warning",
    },
    {
      name: "Maîtrise",
      value: 2,
      icons: ["plus"],
      color: "green-light",
    },
    {
      name: "Maîtrise totale",
      value: 3,
      icons: ["plus", "plus"],
      color: "success",
    },
  ];
  const { url } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (suiviSelected !== null) {
      loadBlocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiviSelected]);

  const loadBlocs = () => {
    let uri =
      url + "api/bloc_afests?formation=" + suiviSelected.promo.formation;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalViewSuiviAfest"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> &nbsp; Consultation du
              suivi de{" "}
              {suiviSelected !== null
                ? suiviSelected.eleve.user.name +
                  " " +
                  suiviSelected.eleve.user.firstName
                : null}
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!loaded ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body mb-3">
              {msg !== null ? (
                <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
              ) : null}
              <div className="d-flex">
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Début</u>
                    </strong>
                  </h6>
                  <span>{dateFormated(suiviSelected.createdAt)}</span>
                </div>
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Fin</u>
                    </strong>
                  </h6>
                  <span>{dateFormated(suiviSelected.validateAt)}</span>
                </div>
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Évaluateur</u>
                    </strong>
                  </h6>
                  <span>
                    {suiviSelected.tuteur
                      ? suiviSelected.tuteur.user.name +
                        " " +
                        suiviSelected.tuteur.user.firstName
                      : "Pas de tuteur"}
                  </span>
                </div>
              </div>
              <div>
                <form>
                  <table className="mt-3 table table-sm table-responsive-md">
                    <thead>
                      <tr>
                        <th></th>
                        {_EVAL.map((val, i) => (
                          <th key={i} className="align-top">
                            {val.name} <br />
                            <span className={`text-${val.color}`}>
                              {val.icons.map((icon, i) => (
                                <FontAwesomeIcon icon={icon} key={i} />
                              ))}
                            </span>
                          </th>
                        ))}
                        <th>Activités</th>
                      </tr>
                    </thead>

                    <tbody>
                      {blocs.map((b, i) => {
                        let notes = suiviSelected.notes.filter(
                          (n) => n.competence.bloc["@id"] === b["@id"]
                        );
                        return notes.length === 0 ? null : (
                          <React.Fragment key={i}>
                            <tr>
                              <th colSpan="6">{b.name}</th>
                            </tr>

                            {notes.sort(triByCompetenceName).map((n, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-left">
                                    {n.competence.name}
                                  </td>
                                  {_EVAL.map((val, it) => {
                                    let check = n.note === val.value;
                                    return (
                                      <td
                                        className={
                                          check ? "text-success" : "text-muted"
                                        }
                                        key={"value" + it}
                                      >
                                        {check ? (
                                          <FontAwesomeIcon icon="check-circle" />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    );
                                  })}
                                  <td className="text-left">
                                    {n.commentaire || ""}
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="mt-3 text-left border border-info p-1">
                <h6>
                  <strong>
                    <u>Commentaires :</u>
                  </strong>
                </h6>
                <p>
                  {suiviSelected.comment
                    ? suiviSelected.comment.split("\n").map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item}
                            <br />
                          </React.Fragment>
                        );
                      })
                    : null}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewSuiviAfest;
