import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EleveItem = ({ student, elevesList, token, maj }) => {
  const { urlEdusign, url } = useContext(AppContext);

  const assign = elevesList.filter((e) => e.IdEdusign === student);

  const [eleveEdusign, setEleveEdusign] = useState(null);
  const [eleveSelected, setEleveSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [save, setSave] = useState(true);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    loadEleve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEleve = () => {
    let uri = urlEdusign.STUDENTS + "/" + student;
    GetBddProvider(uri, token).then((res) => {
      if (typeof res === "object" && res.status === "success") {
        let data = res["result"];
        let eleve = elevesList.filter(
          (e) => e.user.actif && e.user.name === data.LASTNAME
        );
        setEleveEdusign(data);
        setEleveSelected(eleve.length !== 0 ? eleve[0]["@id"] : null);
      } else {
        setMsg({
          txt: "Aucun étudiant avec cet ID n'a été trouvé",
          type: "danger",
        });
      }
      setLoaded(true);
    });
  };

  const saveSync = () => {
    setSave(false);
    let uri = url + eleveSelected.slice(1);
    let data = { IdEdusign: student };
    update(uri, data);
  };

  const supSync = () => {
    setSave(false);
    let uri = url + assign[0]["@id"].slice(1);
    let data = { IdEdusign: null };
    update(uri, data);
  };

  const update = (uri, data) => {
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "les données ont bien été modifiées",
          type: "success",
        });
        autoCloseMsg(setMsg, 2000);
        setSave(true);
        maj(true);
      } else {
        setSave(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 2000);
      }
    });
  };

  return (
    <li className="list-group-item">
      {msg !== null && (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      )}
      {eleveEdusign ? (
        <>
          <h6>
            {eleveEdusign.LASTNAME} {eleveEdusign.FIRSTNAME}
          </h6>
          <div className="form-inline">
            <FontAwesomeIcon
              icon="sync-alt"
              className={`mr-2 ${
                assign.length !== 0 ? "text-success" : "text-warning"
              }`}
            />
            {assign.length === 0 ? (
              <>
                <select
                  className="form-control"
                  value={eleveSelected || ""}
                  onChange={(e) => setEleveSelected(e.target.value)}
                >
                  <option value="" disabled>
                    Sélectionnez un élève
                  </option>
                  {elevesList
                    .filter((e) => !e.IdEdusign && e.user.actif)
                    .map((eleve) => (
                      <option
                        value={eleve["@id"]}
                        key={`${student}${eleve["@id"]}`}
                      >
                        {eleve.user.name} {eleve.user.firstName}
                      </option>
                    ))}
                </select>
                <button
                  className="btn btn-outline-success btn-sm ml-2"
                  title="enregistrer"
                  onClick={() => {
                    saveSync();
                  }}
                  disabled={eleveSelected === null}
                >
                  {save ? <FontAwesomeIcon icon="check" /> : <SmallSpinner />}
                </button>
              </>
            ) : (
              <>
                {assign[0].user.name} {assign[0].user.firstName}
                <button
                  className="btn btn-outline-danger btn-sm ml-2"
                  title="Désynchroniser"
                  onClick={() => supSync()}
                >
                  {save ? (
                    <FontAwesomeIcon icon="times" role="button" />
                  ) : (
                    <SmallSpinner />
                  )}
                </button>
              </>
            )}
          </div>
        </>
      ) : (
        !loaded && (
          <div className="text-center text-info">
            <SmallSpinner />
          </div>
        )
      )}
    </li>
  );
};

export default EleveItem;
