import React, { useState } from "react";
import Layout from "../common/Layout";
import PieChartsComponent from "../charts/PieChartsComponent";
import BarChartsConponent from "../charts/BarChartsConponent";
import RadarChartsComponent from "../charts/RadarChartsComponent";
import RadialBarChartsComponent from "../charts/RadialBarChartsComponent";

function SmecCharts() {
  const [chartSelected, setChartSelected] = useState(null);
  console.log("🚀 ~ file: SmecCharts.js:9 ~ SmecCharts ~ chartSelected:", chartSelected)

  const _TYPE = [
    {
      name: "camenbert",
      component: ()=><PieChartsComponent/>,
    },
    {
      name: "histogramme",
      component: ()=><BarChartsConponent/>,
    },
    {
      name: "radar",
      component: ()=><RadarChartsComponent/>,
    },
    {
      name: "bar radial",
      component: ()=><RadialBarChartsComponent/>,
    },
  ];

  return Layout(
    <div className="mt-4">
      <h1>Vue Graphique SMEC</h1>
      <div className="d-flex justify-content-center m-4">
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1">Type de graphique</label>
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            value={chartSelected || ""}
            onChange={(e) => setChartSelected(e.target.value)}
          >
            <option value="" disabled>
              Choix du type de graphique
            </option>
            {_TYPE.map((t) => (
              <option value={t.name} key={t.name}>
                {t.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {chartSelected !== null && _TYPE.find(t => t.name === chartSelected).component()}
    </div>
  );
}

export default SmecCharts;
