import React from 'react';
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import PromoItemGalia from "./PromoItemGalia";

const SyncPromosGalia = ({
  loaded,
  actions,
  promos,
  maj,
  actionSelected,
  setActionSelected,
  setPromo,
}) => {
  return (
    <div className="card text-left">
      <div className="card-header">
        <h5 className="card-title text-center">Synchronisation des promos</h5>
      </div>
      <div className="card-body p-0">
        {!loaded ? (
          <div className="text-center m-2">
            <Spinner />
          </div>
        ) : (
          <ul className="list-group list-group-flush">
            {actions.length > 0 ? (
              actions.filter(a=> a.Libelle_Court.slice(0,2) !== "TC")
                .map((action) => (
                  <PromoItemGalia
                    action={action}
                    promos={promos}
                    actionSelected={actionSelected}
                    setActionSelected={setActionSelected}
                    setPromo={setPromo}
                    maj={maj}
                    key={action.ID}
                  />
                ))
            ) : (
              <MsgAlert
                msg="Pas de promo dans Galia !"
                type="warning text-center"
              />
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SyncPromosGalia;
