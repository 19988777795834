import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditSuivi from "../contents/edits/EditSuivi";
import dateFormated from "../tools/dateFormated";
import heuresFormated from "../tools/heuresFormated";
import ViewSuivi from "../contents/views/ViewSuivi";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByUserName } from "../tools/sortUtils";

const SuiviPedago = () => {
  const { url, user } = useContext(AppContext);

  let temp = new Date();

  let initDebut = new Date(temp.getFullYear(), temp.getMonth(), 1);

  let initFin = new Date(temp.getFullYear(), temp.getMonth() + 1, 0);

  const [debut, setDebut] = useState(
    new Date(
      initDebut.getFullYear(),
      initDebut.getMonth(),
      initDebut.getDate(),
      -initDebut.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );
  const [fin, setFin] = useState(
    new Date(
      initFin.getFullYear(),
      initFin.getMonth(),
      initFin.getDate(),
      -initFin.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );
  const [promos, setPromos] = useState(null);
  const [listPromos, setListPromos] = useState([]);
  const [promoSelected, setPromoSelected] = useState(null);
  const [eleveSelected, setEleveSelected] = useState(null);
  const [elevesloading, setElevesloading] = useState(false);
  const [eleves, setEleves] = useState(null);
  const [suivis, setSuivis] = useState(null);
  const [suiviSelected, setSuiviSelected] = useState(null);
  const [msg, setMsg] = useState(null);
  const [maj, setMaj] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [actif, setActif] = useState(true);
  let totalH = 0;

  useEffect(() => {
    load();
    loadSuivis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      setSuivis(null);
      loadSuivis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    setEleves(null);
    setEleveSelected(null);
    setElevesloading(true);
    if (promoSelected !== null && promoSelected !== "") {
      loadEleves(promoSelected);
    } else {
      setElevesloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  useEffect(()=>{
    if (actif){
      setListPromos(promos ? promos.filter(p=>p.actif) : [])
    } else {
      setListPromos(promos || [])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actif])

  const load = () => {
    let uri = url + "api/promos?";
    user.sites.forEach((site, i, sites) => {
      uri += sites.length > 1 ? "site[]=" : "site=";
      uri += site["@id"];
      uri += i + 1 < sites.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        let result = res["hydra:member"];
        setPromos(result);
        setListPromos(actif ? result.filter((r) => r.actif) : result);
      } else {
        setMsg({ txt: "Erreur de Chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promoSelected;
    GetBddProvider(uri).then((res) => {
      setElevesloading(false);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadSuivis = () => {
    let uri =
      url +
      "api/suivis?order[createdAt]=asc&createdAt[after]=" +
      debut +
      "&createdAt[before]=" +
      fin;
    if (eleveSelected !== null) {
      uri += "&eleve=" + eleveSelected;
    }
    if (promoSelected === null || promoSelected === "") {
      uri += "&";
      user.sites.forEach((site, i, sites) => {
        uri += sites.length > 1 ? "evaluateur.sites[]=" : "evaluateur.sites=";
        uri += site["@id"];
        uri += i + 1 < sites.length ? "&" : "";
      });
    } else {
      uri += "&eleve.promos=" + promoSelected;
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSuivis(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des suivis", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delSuivi = (iri) => {
    setSuivis(null);
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      loadSuivis();
      if (res === 204) {
        setMsg({
          txt: "Le suivi a été supprimée !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="mt-4">
      <div className="d-flex flex-column flex-xl-row flex-wrap justify-content-md-between justify-content-center mx-4">
        <h2>Gestion des accompagnements</h2>
        <div className="form-row align-items-center  justify-content-center">
          <div className="form-check my-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="checkSuiviActif"
              checked={actif}
              onChange={() => setActif(!actif)}
            />
            <label className="form-check-label" htmlFor="checkSuiviActif">
              <strong>Promos actives</strong>
            </label>
          </div>
        </div>
        <button
          className="btn rounded-pill btn-info align-self-center"
          data-toggle="modal"
          data-target="#ModalAccomp"
        >
          <FontAwesomeIcon icon="edit" /> Saisir un accompagnement
        </button>
      </div>
      <EditSuivi setMaj={setMaj} listPromos={listPromos} />
      <ViewSuivi suiviSelected={suiviSelected} setMaj={setMaj} />
      <hr className="m-4" />
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      {!loaded || promos === null ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="form-row mx-4">
            <div className="form-group col-lg-6">
              <label>Promo</label>
              <select
                className="form-control  rounded-pill"
                value={promoSelected || ""}
                onChange={(e) => {
                  setPromoSelected(e.target.value);
                }}
              >
                <option value="">Toutes les promos</option>
                {listPromos.map((p, i) => (
                  <option value={p["@id"]} key={i}>
                    {p.site.name} - {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label>Eleves</label>
              <select
                className="form-control rounded-pill"
                value={eleveSelected || ""}
                onChange={(e) => setEleveSelected(e.target.value)}
                disabled={eleves === null}
              >
                <option value="">
                  {elevesloading
                    ? "Chargement en cours ..."
                    : eleves === null
                    ? "Veuillez sélectionner une promo"
                    : eleves.length < 1
                    ? "Pas d'élèves"
                    : "Tous les élèves"}
                </option>
                {eleves === null
                  ? null
                  : eleves.sort(triByUserName).map((el, i) => (
                      <option value={el["@id"]} key={i}>
                        {el.user.name} {el.user.firstName}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-5">
              <label>
                Début de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-5">
              <label>
                Fin de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-2 align-self-end">
              <button
                className="btn btn-sm rounded-pill btn-outline-primary form-control"
                onClick={() => {
                  setSuivis(null);
                  loadSuivis();
                }}
              >
                Afficher
              </button>
            </div>
          </div>
          <hr className="m-4" />
          <div className="m-4 animFadeIn">
            {suivis === null ? (
              <Spinner />
            ) : (
              <div>
                {suivis.length < 1 ? (
                  <h4 className="text-danger m-4">
                    <strong>Pas de suivi sur la période</strong>
                  </h4>
                ) : (
                  <div>
                    <h4 className="m-4">Liste des suivis</h4>
                    <table
                      className="table table-sm table-bordered table-responsive-md"
                      id="TableSuivis"
                    >
                      <thead>
                        <tr className="bg-info text-white">
                          <th>Action</th>
                          <th>Date</th>
                          <th>Eleve</th>
                          <th>Evaluateur</th>
                          <th>Validé</th>
                          <th>Durée</th>
                        </tr>
                      </thead>
                      <tbody className="text-left">
                        {suivis.map((s, i) => {
                          let h = parseFloat(heuresFormated(s.time));
                          let hString =
                            String(h).split(".")[1] !== undefined
                              ? String(h).split(".")[0] +
                                "," +
                                String(h).split(".")[1]
                              : String(h).split(".")[0];
                          totalH += h;
                          return (
                            <tr key={i}>
                              <td>
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSuiviSelected(
                                      JSON.parse(JSON.stringify(s))
                                    );
                                  }}
                                  data-toggle="modal"
                                  data-target="#ModalViewSuivi"
                                  className="text-info"
                                  icon="clipboard-list"
                                  title="Visualiser"
                                />{" "}
                                &nbsp;{" "}
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      window.confirm("Supprimer le suivi ?")
                                    ) {
                                      delSuivi(s["@id"]);
                                    }
                                  }}
                                  className="text-danger"
                                  icon="trash-alt"
                                  title="Supprimer"
                                />
                              </td>
                              <td>{dateFormated(s.createdAt)}</td>
                              <td>
                                {s.eleve.user.name} {s.eleve.user.firstName}
                              </td>
                              <td>
                                {s.evaluateur.name} {s.evaluateur.firstName}
                              </td>
                              <td className="text-center">
                                {s.validate ? (
                                  <span className="badge badge-pill badge-success m-1 text-white">
                                    Oui
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-danger m-1 text-white">
                                    Non
                                  </span>
                                )}
                              </td>
                              <td className="text-center">{hString}</td>
                            </tr>
                          );
                        })}
                        <tr className="text-info">
                          <td colSpan="5" className="text-right">
                            <h4>Total :</h4>
                          </td>
                          <td className="text-center">
                            <h4>{totalH}</h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ReactHTMLTableToExcel
                      className="btn btn-outline-primary rounded-pill"
                      table="TableSuivis"
                      filename={
                        "relevé-suivis-" +
                        (new Date(debut).getMonth() + 1) +
                        "-" +
                        new Date(debut).getFullYear()
                      }
                      sheet="Export"
                      buttonText="Export Excel"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SuiviPedago;
