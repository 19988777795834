import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import useGet from "../../hooks/useGet";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import ListExportEleves from "../contents/lists/ListExportEleves";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

export default function ExportEleves() {
  const { user, url } = useContext(AppContext);

  const getBdd = useGet();

  const [siteSelected, setSiteSelected] = useState(null);
  const [sites, setSites] = useState(user.sites);

  const [promoSelected, setPromoSelected] = useState(null);
  const [promos, setPromos] = useState([]);
  const [promosloaded, setPromosloaded] = useState(false);

  const [eleves, setEleves] = useState([]);
  const [elevesloaded, setElevesloaded] = useState(false);

  const [actif, setActif] = useState(true);
  const [eleveActif, setEleveActif] = useState(true);

  const [loaded, setloaded] = useState(false);

  const [msg, setMsg] = useState(null);

  useEffect(() => {
    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setPromosloaded(false);
      setElevesloaded(false);
      setPromoSelected(null);
      loadPromos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected, actif]);

  useEffect(() => {
    setElevesloaded(false);
  }, [promoSelected]);

  const loadSites = () => {
    if (user.role === 1) {
      let uri = `${url}api/sites`;
      getBdd(uri).then((res) => {
        if (typeof res === "object") {
          let result = res["hydra:member"];
          setSites(result);
          setloaded(true);
        } else {
          setMsg({
            txt: "Erreur de chargement des sites",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      setSites(user.sites);
      if (user.sites.length === 1) {
        setSiteSelected(user.sites[0]["@id"]);
      }
      setloaded(true);
    }
  };

  const loadPromos = () => {
    let uri = `${url}api/promos?site=${siteSelected}`;
    if (actif) {
      uri += `&actif=${actif}`;
    }
    getBdd(uri).then((res) => {
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
        setPromosloaded(true);
      } else {
        setMsg({
          txt: "Erreur de chargement des promos",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadEleves = (e) => {
    e.preventDefault();
    let uri = `${url}api/eleves?promos=${promoSelected}`;
    if (eleveActif) {
      uri += "&user.actif=" + eleveActif;
    }
    getBdd(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
        setElevesloaded(true);
      } else {
        setMsg({
          txt: "Erreur de chargement des élèves",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="m-4">
      <h2>Export des élèves</h2>
      <hr />
      {!loaded ? (
        <Spinner />
      ) : (
        <>
          <div className="ml-4 form-group">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actif || false}
                onChange={(e) => setActif(!actif)}
              />
              <label className="form-check-label">
                <strong>Promos actives uniquement</strong>
              </label>
            </div>
          </div>
          <div className="text-center d-md-flex justify-content-md-around align-items-center">
            <div className="form-group">
              <label htmlFor="choixSite">Site</label>
              <select
                className="form-control w-auto m-auto"
                id="choixSite"
                value={siteSelected || ""}
                onChange={(e) => setSiteSelected(e.target.value)}
              >
                <option value="" disabled>
                  Choix du site
                </option>
                {sites.sort(triByName).map((s) => (
                  <option value={s["@id"]} key={s["@id"]}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="choixPromo">Promo</label>
              <select
                className="form-control w-auto m-auto"
                name="choixPromo"
                value={promoSelected || ""}
                onChange={(e) => setPromoSelected(e.target.value)}
                disabled={!promosloaded}
              >
                <option value="" disabled>
                  Choix de la promo
                </option>
                {promos.sort(triByName).map((p) => (
                  <option value={p["@id"]} key={p["@id"]}>
                    {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={eleveActif || false}
                onChange={(e) => setEleveActif(!eleveActif)}
              />
              <label className="form-check-label">
                <strong>Elèves actives uniquement</strong>
              </label>
            </div>
            <div className="form-group">
              <button
                className="btn btn-info"
                onClick={loadEleves}
                disabled={promoSelected === null}
              >
                Générer
              </button>
            </div>
          </div>
          <hr />
        </>
      )}
      {msg && <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />}
      {elevesloaded && (
        <ListExportEleves
          promo={promos.filter((p) => p["@id"] === promoSelected)[0]}
          eleves={eleves}
        />
      )}
    </div>
  );
}
