import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormated from "../../tools/dateFormated";
import AppContext from "../../../Context/AppContext";

const ListSuiviEleve = ({ suivis, view, setSuiviSelected, type }) => {

  const { user } = useContext(AppContext);

  const _AFEST = "afest";
  const _COMP = "competence";
  const _SUIVI = "suivi";

  let title = "";

  let modal = view;

  if (type === _AFEST) {
    title = "Evaluation en situation de travail";
  } else if (type === _COMP) {
    title = "Evaluation des compétences";
  } else if (type === _SUIVI) {
    title = "Suivi pédagogique";
  }

  return (
    <div>
      <h4 className="m-4">{title}</h4>
      <table
        className="table table-sm table-bordered table-responsive-md"
        id="TableSuivis"
      >
        <thead>
          <tr className="bg-info text-white">
            <th>Action</th>
            <th>Date</th>
            <th>{type === _AFEST ? "Intitulé" : "Evaluateur"}</th>
            <th>Validé</th>
          </tr>
        </thead>
        <tbody className="text-left">
          {suivis.map((s, i) => {
            if (type === _AFEST && user.role > 5 && !s.validate) {
              modal = "#ModalSaisiAfest";
            }
            return (
              <tr key={i}>
                <td>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSuiviSelected(JSON.parse(JSON.stringify(s)));
                    }}
                    data-toggle="modal"
                    data-target={modal}
                    className="text-info"
                    icon="clipboard-list"
                    title="Visualiser"
                  />
                </td>
                <td>{dateFormated(s.createdAt)}</td>
                <td>
                  {type === _AFEST
                    ? s.name
                    : s.evaluateur.name + " " + s.evaluateur.firstName}
                </td>
                <td className="text-center">
                  {s.validate ? (
                    <span className="badge badge-pill badge-success m-1 text-white">
                      Oui
                    </span>
                  ) : (
                    <span className="badge badge-pill badge-danger m-1 text-white">
                      Non
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ListSuiviEleve;
