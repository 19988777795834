import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SidebarDropdown from "./SidebarDropdown";
import SidebarItem from "./SidebarItem";
import { StorageService } from "../tools/Storage/StorageService";

const SidebarMenu = () => {
  const disconnect = () => {
    StorageService.clear();
    window.location.reload();
  };

  return StorageService.getItem("token") === null ? null : (
    <aside
      className="navbar navbar-expand-lg navbar-light bg-light p-0 align-items-start border-right shadow"
      style={{ minHeight: "calc(100vh - 40px)" }}
    >
      <div
        id="sidebarMenu"
        className="collapse navbar-collapse text-left"
        style={{ width: "280px" }}
      >
        <nav>
          <ul
            className="navbar-nav position-fixed px-1 pt-1 overflow-auto flex-column"
            style={{ width: "280px", height: "calc(100vh - 40px)" }}
          >
            <SidebarItem name="Accueil" icon="home" path="/" exact />
            <SidebarItem
              name="Planning"
              icon="calendar-alt"
              path="/planning"
              role={5}
            />
            <SidebarItem
              name="Devoirs"
              icon="book"
              path="/undefined"
              role={5}
            />
            <SidebarItem
              name="Progressions Pédagogiques"
              role={6}
              exclude={5.5}
              icon="chart-line"
              path="/progressions"
            />
            <SidebarItem
              name="Relevé d'heures"
              role={4}
              icon="history"
              path="/releveHeures"
            />
            <SidebarDropdown
              role={4}
              icon="clipboard-list"
              name="Gestion des notes"
              items={[
                {
                  role: 4,
                  name: "Notes",
                  icon: "clipboard-list",
                  path: "/notes",
                },
                {
                  name: "Prépa conseil",
                  role: 4,
                  icon: "tasks",
                  path: "/prepaconseil",
                },
                {
                  name: "Prépa livret",
                  role: 3,
                  icon: "eye",
                  path: "/preLivret",
                },
              ]}
            />
            <SidebarDropdown
              role={3.5}
              icon="clipboard-list"
              name="Suivis"
              items={[
                {
                  role: 3.5,
                  name: "Comportement",
                  icon: "tasks",
                  path: "/suiviPedago",
                },
                {
                  name: "Compétences / SMEC",
                  role: 3.5,
                  icon: "clipboard-list",
                  path: "/suiviComp",
                },
                // {
                //   name: "SMEC",
                //   role: 3.5,
                //   icon: "clipboard-list",
                //   path: "/suiviAfest",
                // },
                {
                  name: "SMEC graph",
                  role: 1,
                  icon: "clipboard-list",
                  path: "/smecCharts",
                },
              ]}
            />
            <SidebarDropdown
              role={3}
              name="Absences/Retards"
              icon="graduation-cap"
              items={[
                {
                  name: "Absences/rattrapages",
                  role: 3,
                  icon: "user-times",
                  path: "/absences",
                },
                {
                  name: "Retards/départs",
                  role: 3,
                  icon: "user-clock",
                  path: "/retards",
                },
                {
                  name: "Temps de présence",
                  role: 3,
                  icon: "user-clock",
                  path: "/presences",
                },
              ]}
            />
            <SidebarDropdown
              role={3}
              name="Administration"
              icon="cogs"
              items={[
                {
                  name: "Gestion des promos",
                  role: 3,
                  icon: "graduation-cap",
                  path: "/promos",
                },
                {
                  name: "Gestion des élèves",
                  role: 3,
                  icon: "user-graduate",
                  path: "/eleves",
                },
                // {
                //   name: "Liste des utilisateurs",
                //   role: {3},
                //   icon: "users-cog",
                //   path: "/users",
                // },
                {
                  name: "Gestion des formateurs",
                  role: 3,
                  icon: "chalkboard-teacher",
                  path: "/formateurs",
                },
                {
                  name: "Gestion des tuteurs",
                  role: 3,
                  icon: "user-tie",
                  path: "/entreprises",
                },
                {
                  name: "Gestion des entreprises",
                  role: 3,
                  icon: "user-tie",
                  path: "/entrepriseAdmins",
                },
                {
                  name: "Gestion des semestres",
                  role: 3,
                  icon: "calendar-alt",
                  path: "/semestres",
                },
                {
                  name: "Gestion des Sites",
                  role: 3,
                  icon: "university",
                  path: "/adminSites",
                },
                {
                  name: "Sync Edusign",
                  role: 3,
                  icon: "sync-alt",
                  path: "/syncEdusign",
                },
                {
                  name: "Sync Galia",
                  role: 3,
                  icon: "sync-alt",
                  path: "/syncGalia",
                },
                {
                  name: "Export Progressions",
                  role: 3,
                  icon: "cogs",
                  path: "/exportProgressions",
                },
                {
                  name: "Export Programme",
                  role: 3,
                  icon: "cogs",
                  path: "/exportProg",
                },
                {
                  name: "Export Elèves",
                  role: 3,
                  icon: "cogs",
                  path: "/exportEleves",
                },
              ]}
            />
            <SidebarDropdown
              role={1}
              name="Configuration"
              icon="cogs"
              items={[
                {
                  name: "Eléments de progression",
                  role: 1,
                  icon: "tasks",
                  path: "/progItems",
                },
                {
                  name: "Gestion des formations",
                  role: 1,
                  icon: "chalkboard",
                  path: "/formations",
                },
                {
                  name: "Gestion des matières",
                  role: 1,
                  icon: "chalkboard",
                  path: "/matieres",
                },
                {
                  name: "Config Item Suivi",
                  role: 1,
                  icon: "cogs",
                  path: "/setItemSuivi",
                },
                {
                  name: "Config Eval Comp",
                  role: 1,
                  icon: "cogs",
                  path: "/setCompetence",
                },
                {
                  name: "Config AFEST",
                  role: 1,
                  icon: "cogs",
                  path: "/setAfest",
                },
                {
                  name: "Gestion des sites",
                  role: 1,
                  icon: "university",
                  path: "/gestSites",
                },
                {
                  name: "Pseudos",
                  role: 1,
                  icon: "university",
                  path: "/AdminUsername",
                },
                {
                  name: "Messages info",
                  role: 1,
                  icon: "cogs",
                  path: "/gestMessageInfo",
                },
              ]}
            />
            <SidebarItem
              name="Support"
              role={4}
              icon="play"
              path="/support"
              color="text-success"
            />
            <SidebarItem
              name="SMEC beta"
              role={3}
              icon="flask"
              path="/smecBeta"
              color="text-warning"
              beta={true}
            />
            <li className="nav-item">
              <span
                className="nav-link text-danger"
                role="button"
                onClick={disconnect}
              >
                <FontAwesomeIcon icon="power-off" /> Déconnexion
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SidebarMenu;
