import React from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import ListNotes from "./lists/ListNotes";
import Spinner from "../common/Spinner";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormated from "../tools/dateFormated";
import ViewSuiviComp from "./views/ViewSuiviComp";
import ViewSuivi from "./views/ViewSuivi";
import SasiAfest from "./edits/SaisiAfest";
import MsgAlert from "../common/MsgAlert";
import ViewSuiviAfest from "./views/ViewSuiviAfest";
import ListSuiviEleve from "./lists/ListSuiviEleve";
import SuiviSmec from "./edits/SuiviSmec";
import monthDiff from "../tools/monthDiff";
import { autoCloseMsg } from "../tools/messagesUtils";

// trier et affichage par matière + calcule moyenne

const Eleve = (props) => {
  const location = useLocation();

  const listEleves = location.state ? location.state.list : props.list;
  const { url, user } = useContext(AppContext);

  const types = [
    "Suivi pédagogique",
    "Suivi de compétences",
    "Evaluation de compétences",
  ];

  const [eleveId, setEleveId] = useState(
    location.state ? location.state.id : props.id
  );

  const [eleve, setEleve] = useState();
  const [tuteur, setTuteur] = useState(null);
  const [index, setIndex] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [promo, setPromo] = useState(null);
  const [afests, setAfests] = useState(null);
  const [saisiAfest, setSaisiAfest] = useState(false);
  const [competences, setCompetences] = useState(null);
  const [suivis, setSuivis] = useState(null);
  const [suiviSelected, setSuiviSelected] = useState(null);
  const [compSelected, setCompSelected] = useState(null);
  const [afestSelected, setAfestSelected] = useState(null);
  const [maj, setMaj] = useState(false);
  const [msg, setMsg] = useState(null);
  const [notes, setNotes] = useState(null);

  useEffect(() => {
    if (eleveId && (eleve === undefined || eleveId !== eleve["@id"])) {
      loaded && setloaded(false);
      raz();
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eleveId]);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      resetSuivis();
      loadSuivis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    // Si user = tuteur chercher les les suivis non validés
    if (eleve !== undefined && promo !== null) {
      loadSuivis();
    }
    if (eleve !== undefined && promo !== null) {
      loadNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eleve, promo]);

  useEffect(() => {
    if (afests !== null) {
      if (afests.length > 0) {
        let tmp = afests.sort(sortTime);
        let today = new Date();
        let nbMonth = monthDiff(new Date(tmp[0].createdAt), today);

        if (nbMonth >= 2 && today.getDate() >= 3) {
          setSaisiAfest(true);
        } else if (saisiAfest) {
          setSaisiAfest(false);
        }
      } else {
        setSaisiAfest(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afests]);

  const sortTime = (a, b) => {
    let da = new Date(a.createdAt);
    let db = new Date(b.createdAt);
    return da > db ? -1 : 1;
  };

  const raz = () => {
    suivis && setSuivis(null);
    competences && setCompetences(null);
    afests && setAfests(null);
  };

  const loadSuivis = () => {
    //recherche des suivis non validé
    GetBddProvider(`${url}api/suivis?eleve=${eleve["@id"]}`).then((res) => {
      if (typeof res === "object" && res["hydra:member"].length > 0) {
        setSuivis(res["hydra:member"]);
      } else if (typeof res !== "object") {
        setMsg({ msg: "Erreur de chargement des suivis", Type: "danger" });
      }
    });
    // recherche des AFEST
    GetBddProvider(`${url}api/eval_afests?eleve=${eleve["@id"]}`).then(
      (res) => {
        if (typeof res === "object" && res["hydra:member"]) {
          setAfests(res["hydra:member"]);
        } else if (typeof res !== "object") {
          setMsg({
            msg: "Erreur de chargement des Evaluations",
            Type: "danger",
          });
        }
      }
    );
    // recherche Evaluation des compétences
    GetBddProvider(`${url}api/eval_competences?eleve=${eleve["@id"]}`).then(
      (res) => {
        if (typeof res === "object" && res["hydra:member"].length > 0) {
          setCompetences(res["hydra:member"]);
        } else if (typeof res !== "object") {
          setMsg({
            msg: "Erreur de chargement des evaluations de compétences",
            Type: "danger",
          });
        }
      }
    );
  };

  const load = () => {
    if (listEleves !== undefined) {
      listEleves.forEach((e, i) => {
        if (e.id === eleveId) {
          setIndex(i);
        }
      });
    }
    const uri = url + "api/eleves/" + eleveId;

    GetBddProvider(uri).then((result) => {
      if (typeof result === "object" && result.id) {
        setEleve(result);
        setPromo(
          result.promos.filter((e) =>
            location.state && location.state.promo
              ? e["@id"] === location.state.promo
              : e.actif === true
          )[0]
        );
      }
    });

    if (user.role >= 6) {
      let uri = url + "api/entreprises?user=" + user["@id"];
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object" && res["hydra:member"].length > 0) {
          setTuteur(res["hydra:member"][0]);
        }
      });
    }
  };

  const loadNotes = () => {
    let uri = url + "api/notes?eleve=" + eleve["@id"] + "&";
    promo.semestres.forEach((s, i, tab) => {
      uri += tab.length > 1 ? "semestre[]=" : "semestre=";
      uri += s["@id"];
      uri += i + 1 < tab.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setNotes(res["hydra:member"]);
        setloaded(true);
      }
    });
  };

  const resetSuivis = () => {
    setAfests(null);
    setSuivis(null);
    setCompetences(null);
  };

  const AlertSuivis = ({ type, evals }) => {
    let modalName;
    switch (parseInt(type)) {
      case 0:
        modalName = "#ModalViewSuivi";
        break;
      case 1:
        modalName = "#ModalViewSuiviCompetences";
        break;
      case 2:
        modalName = "#ModalSaisiAfest";
        break;
      default:
        break;
    }

    return (
      <div className="alert accordion p-0 mx-2" id={`accordionAlert${type}`}>
        <div
          id={`headingAlert${type}`}
          className="m-0 text-danger border border-danger p-2"
          type="button"
          data-toggle="collapse"
          data-target={`#collapseAlert${type}`}
          aria-expanded={false}
          aria-controls={`collapseAlert${type}`}
        >
          <h2>
            <FontAwesomeIcon icon="exclamation-triangle" />
          </h2>
          <u>
            <strong>
              Il y a {evals.length} {types[type]}
              {evals.length > 1 ? "s" : ""} à{" "}
              {type === "2" ? "saisir" : "valider"}
            </strong>
          </u>
          <h5>
            <FontAwesomeIcon icon="chevron-down" />
          </h5>
        </div>

        <div
          className="collapse"
          id={`collapseAlert${type}`}
          aria-labelledby={`headingAlert${type}`}
          data-parent={`#accordionAlert${type}`}
        >
          <ul className="list-group alert-danger border border-danger mt-1">
            {evals.map((el, i) => {
              return (
                <li
                  className="list-group-item bg-transparent border-0"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (type === "0") {
                      setSuiviSelected(JSON.parse(JSON.stringify(el)));
                    } else if (type === "1") {
                      setCompSelected(JSON.parse(JSON.stringify(el)));
                    } else {
                      setAfestSelected(JSON.parse(JSON.stringify(el)));
                    }
                  }}
                  data-toggle="modal"
                  data-target={modalName} //Nommé en fonction du type
                  key={i}
                >
                  {type === "2"
                    ? el.name
                    : "Suivi du " + dateFormated(el.createdAt)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loaded ? (
        <div>
          {user.role > 5 && (
            <React.Fragment>
              <SasiAfest suiviSelected={afestSelected} setMaj={setMaj} />
            </React.Fragment>
          )}
          <ViewSuiviComp suiviSelected={compSelected} setMaj={setMaj} />
          <ViewSuivi suiviSelected={suiviSelected} setMaj={setMaj} />
          <ViewSuiviAfest suiviSelected={afestSelected} />
          {promo && eleve && (user.role >= 6 || user.role === 1) && (
            <SuiviSmec
              promo={promo}
              eleve={eleve}
              tuteur={tuteur}
              saisie={saisiAfest}
              setMaj={setMaj}
            />
          )}
          <div className="d-flex justify-content-between">
            <div>
              {index !== null && index > 0 ? (
                <button
                  className="btn btn-info m-4"
                  onClick={() => setEleveId(listEleves[index - 1].id)}
                >
                  Précédent
                </button>
              ) : null}
            </div>
            <span className="m-3">
              <h2 className="mb-0">
                {eleve.user.name + " " + eleve.user.firstName}
              </h2>
              <h3 className="font-italic">{promo.name}</h3>
              <h5>
                <em>
                  (
                  {eleve.entreprises.length > 0
                    ? eleve.entreprises[0].raisonSociale
                    : "Pas d'entreprise affectée"}
                  )
                </em>
              </h5>
              {(user.role >= 6 || user.role === 1) && saisiAfest && (
                <span
                  className="btn btn-danger"
                  data-toggle="modal"
                  data-target="#ModalSMEC"
                >
                  Saisie SMEC
                </span>
              )}
            </span>
            <div>
              {index !== null && index < listEleves.length - 1 ? (
                <button
                  className="btn btn-info m-4"
                  onClick={() => setEleveId(listEleves[index + 1].id)}
                >
                  Suivant
                </button>
              ) : null}
            </div>
          </div>
          {msg !== null ? (
            <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
          ) : null}
          {user.role > 5 ? (
            <div className="d-flex justify-content-center p-0 m-4 rounded">
              {suivis && suivis.filter((s) => !s.validate).length > 0 ? (
                <AlertSuivis
                  type="0"
                  evals={suivis.filter((s) => !s.validate)}
                />
              ) : null}
              {competences &&
              competences.filter((s) => !s.validate).length > 0 ? (
                <AlertSuivis
                  type="1"
                  evals={competences.filter((s) => !s.validate)}
                />
              ) : null}
              {afests && afests.filter((s) => !s.validate).length > 0 ? (
                <AlertSuivis
                  type="2"
                  evals={afests.filter((s) => !s.validate)}
                />
              ) : null}
            </div>
          ) : null}
          <hr className="mx-3" />
          <ListNotes eleve={eleve} listNotes={notes} promo={promo} />
          <hr className="mx-3" />
          {suivis && suivis.length > 0 && (
            <ListSuiviEleve
              type="suivi"
              suivis={suivis}
              view="#ModalViewSuivi"
              setSuiviSelected={setSuiviSelected}
            />
          )}
          {competences && competences.length > 0 && (
            <ListSuiviEleve
              type="competence"
              suivis={competences}
              view="#ModalViewSuiviCompetences"
              setSuiviSelected={setCompSelected}
            />
          )}
          {afests && afests.length > 0 && (
            <ListSuiviEleve
              type="afest"
              suivis={afests}
              view="#ModalViewSuiviAfest"
              setSuiviSelected={setAfestSelected}
            />
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Eleve;
