import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import { triByMoyenne, triByName } from "./sortUtils";

const BulletinPDF = ({
  promoUri,
  eleve,
  semestre,
  matieres,
  comments,
  commentSemestre,
  abs,
  noJustify,
  justify,
  rattrapages,
  rtd,
}) => {
  const { url, urlPdf } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState(null);
  const [generate, setGenerate] = useState(false);
  const [charged, setCharged] = useState(false);
  const [moyMat, setMoyMat] = useState([]);
  const [moyCl, setMoyCl] = useState([]);
  const [moyExamCl, setMoyExamCl] = useState([]);
  const [moyGlobal, setMoyGlobal] = useState(null);
  const [promo, setPromo] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (generate === true) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate]);

  useEffect(() => {
    if (loading === true) {
      calcMoyMat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (moyMat.length > 0) {
      calcMoyGen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moyMat]);

  useEffect(() => {
    if (moyGlobal !== null) {
      generateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moyGlobal]);

  const load = () => {
    //charger les notes du semestre
    let uri = url + "api/notes?semestre=" + semestre["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setNotes(res["hydra:member"]);
        let uri2 = url + promoUri.slice(1);
        GetBddProvider(uri2).then((res) => {
          if (typeof res === "object") {
            setPromo(res);
            GetBddProvider(url + "api/eleves?promos=" + res["@id"]).then(
              (res) => {
                if (typeof res === "object") {
                  setEleves(res["hydra:member"]);
                  setLoading(true);
                }
              }
            );
          }
        });
      }
    });
  };

  const calcMoy = (val, coef = false) => {
    let x = null;
    let y = null;
    val.forEach((n) => {
      if (n.moyenne !== undefined && n.moyenne !== null) {
        if (coef) {
          if (n.coef !== 0) {
            x += parseFloat(n.moyenne) * parseFloat(n.coef);
            y += n.coef;
          }
        } else {
          x += parseFloat(n.moyenne);
          y += 1;
        }
      }
      if (n.note !== undefined && n.note !== null) {
        if (coef) {
          if (n.coef !== 0) {
            x += parseFloat(n.note) * parseFloat(n.coef);
            y += n.coef;
          }
        } else {
          x += parseFloat(n.note);
          y += 1;
        }
      }
    });
    let moy = x !== null && y !== null ? Math.round((x / y) * 100) / 100 : null;
    return moy;
  };

  const createTabMoyennes = (val) => {
    let tmpNotes = [];
    eleves.forEach((e) => {
      matieres.forEach((m) => {
        let temp = val.filter(
          (n) => n.eleve === e["@id"] && n.matiere === m["@id"]
        );
        let coef = eleve.promos
          .filter((p) => p["@id"] === promoUri)[0]
          .formation.coefs.filter(
            (c) => c.matiere["@id"] === m["@id"]
          )[0].coefficient;
        tmpNotes = [
          ...tmpNotes,
          {
            eleve: e["@id"],
            matiere: m["@id"],
            coef,
            moyenne: calcMoy(temp, true),
          },
        ];
      });
    });
    setMoyCl(tmpNotes);
    return tmpNotes;
  };

  const createTabMoyennesExam = (val) => {
    let tmpNotes = [];
    eleves.forEach((e) => {
      if (e.user.actif) {
        matieres.forEach((m) => {
          let temp = val.filter(
            (n) => n.eleve === e["@id"] && n.matiere === m["@id"]
          );
          let coef = eleve.promos
            .filter((p) => p["@id"] === promoUri)[0]
            .formation.coefs.filter(
              (c) => c.matiere["@id"] === m["@id"]
            )[0].coefficient;
          tmpNotes = [
            ...tmpNotes,
            {
              eleve: e["@id"],
              matiere: m["@id"],
              coef,
              moyenne: calcMoy(temp, false),
            },
          ];
        });
      }
    });
    setMoyExamCl(tmpNotes);
    return tmpNotes;
  };

  const calcMoyMat = () => {
    let moyennesTmp = [];
    let tabMoy = createTabMoyennes(notes);
    let tabMoyExam = createTabMoyennesExam(notes.filter((n) => n.exam));
    matieres.sort(triByName).forEach((m) => {
      let coef = eleve.promos
        .filter((p) => p["@id"] === promoUri)[0]
        .formation.coefs.filter(
          (c) => c.matiere["@id"] === m["@id"]
        )[0].coefficient;
      let temp = tabMoy.filter(
        (n) => n.matiere === m["@id"] && n.moyenne !== null
      );
      let tempExam = tabMoyExam.filter((n) => n.matiere === m["@id"]);
      moyennesTmp = [
        ...moyennesTmp,
        {
          matiere: m,
          name: m.name,
          moyMin: temp.length > 0 ? temp.sort(triByMoyenne)[0].moyenne : null,
          moyMax:
            temp.length > 0 ? temp.sort(triByMoyenne)[temp.length - 1].moyenne : null,
          moyCl: calcMoy(temp, false),
          moyExam: tempExam.filter((t) => t.eleve === eleve["@id"])[0],
          moyenne:
            temp.filter((t) => t.eleve === eleve["@id"]).length > 0
              ? temp.filter((t) => t.eleve === eleve["@id"])[0].moyenne
              : null,
          comment: comments.filter((c) => c.matiere === m["@id"]) || "",
          coef,
        },
      ];
    });
    setMoyMat(moyennesTmp);
  };

  const calcMoyGenAll = () => {
    let tempMoy = [];
    eleves.forEach((e) => {
      if (e.user.actif) {
        let temp = moyCl.filter((m) => {
          return m.eleve === e["@id"] && m.moyenne !== null;
        });
        tempMoy = [
          ...tempMoy,
          {
            eleve: e["@id"],
            moyenne: calcMoy(temp, true),
          },
        ];
      }
    });
    return tempMoy;
  };

  const calcMoyGen = () => {
    let temp;
    let listMoyCl = calcMoyGenAll().filter((m) => m.moyenne !== null);
    if (listMoyCl.length > 0) {
      temp = {
        moyMin: listMoyCl.sort(triByMoyenne)[0].moyenne,
        moyMax: listMoyCl.sort(triByMoyenne)[listMoyCl.length - 1].moyenne,
        moyCl: calcMoy(listMoyCl, false),
        moyExam: calcMoy(
          moyExamCl.filter((n) => n.eleve === eleve["@id"]),
          true
        ),
        moyenne: calcMoy(moyMat, true),
      };
    }
    setMoyGlobal(temp);
  };

  const generateData = () => {
    setData({
      ...data,
      eleve,
      site: promo.site,
      semestre,
      commentSemestre,
      title: "Relevé de notes",
      promo,
      moyennes: moyMat,
      moyennesGlobales: moyGlobal ? moyGlobal : null,
      absences: abs,
      absNoJustify: noJustify,
      absJustify: justify,
      rattrapages: rattrapages,
      retards: rtd,
    });
    setCharged(true);
  };

  return (
    <React.Fragment>
      <form
        className="form-inline"
        action={urlPdf}
        method="POST"
        target="_blank"
      >
        <input
          className="form-control"
          type="textarea"
          name="data"
          defaultValue={charged ? JSON.stringify(data) : null}
          hidden
        />
        {charged ? (
          <button className="btn btn-success" type="submit">
            Afficher le bulletin
          </button>
        ) : null}
      </form>
      {!charged ? (
        <button
          className={
            generate ? "btn btn-outline-warning" : "btn btn-outline-primary"
          }
          onClick={() => setGenerate(true)}
        >
          {generate ? (
            <span>
              {" "}
              chargement &nbsp;
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </span>
          ) : (
            "Générer le bulletin"
          )}
        </button>
      ) : null}
    </React.Fragment>
  );
};

export default BulletinPDF;
