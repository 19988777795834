import { autoCloseMsg } from "../../../Components/tools/messagesUtils";

/**
 * @param {Object, number} abort
 * @param {Object{
 *    setMSg?: function(string):void,
 *    errorMessage?: string
 *    typeError?: string
 *    autoCloseTime?: number
 * }} options
 */
export const LoaderErrorMessage = (res, options) => {
  let abort = res === 666 ? true : false;
  if (options.setMsg) {
    options.setMsg({
      txt: abort
        ? "Chargement annulé"
        : options.errorMessage ?? "Erreur de chargement",
      type: abort ? "warning" : options.typeError ?? "danger",
    });
    options.autoCloseTime &&
      autoCloseMsg(options.setMsg, options.autoCloseTime);
  }
};
