import React from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import ListExportElevesLine from "./ListExportElevesLine";

export default function ListExportEleves({ promo, eleves }) {
  return (
    <div>
      <ReactHtmlTableToExcel
        className="btn btn-outline-info btn-sm my-2"
        table="tableEleves"
        filename={`Export Elèves - ${promo.name} - ${promo.site.name}`}
        sheet="Export"
        buttonText="Export Excel"
      />
      <h3>{promo.name}</h3>
      <table className="table table-sm table-bordered small" id="tableEleves">
        <thead>
          <tr className="bg-info text-white">
            <th>Élève</th>
            <th>Mail élève</th>
            <th>Entreprise</th>
            <th>Tuteur</th>
            <th>Adresse</th>
            <th>CP</th>
            <th>Ville</th>
            <th>Téléphone</th>
            <th>Mail tuteur</th>
            <th className="d-none">Mail admin</th>
            <th className="d-none">Autre e-mail</th>
          </tr>
        </thead>
        <tbody className="text-left">
          {eleves.map((e) => (
            <ListExportElevesLine eleve={e} key={e["@id"]} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
