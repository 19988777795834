import React from "react";
import { useState } from "react";
import Spinner from "../common/Spinner";
import ListPromosFormateur from "../contents/lists/ListPromosFormateur";
import MsgAlert from "../common/MsgAlert";
import ModalDev from "../contents/ModalDev";
import EditProgression from "../contents/edits/EditProgression";
import EditNotes from "../contents/edits/EditNotes";
import PageFormateurContext from "../../Context/PageFormateurContext";
import EditCommentMat from "../contents/edits/EditCommentMat";
import EditAbsences from "../contents/edits/EditAbsences";
import ModifAbsence from "../contents/modifs/ModifAbsences";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import EditRetard from "../contents/edits/EditRetard";
import ModifRetard from "../contents/modifs/ModifRetard";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const PageFormateur = ({ profil }) => {
  const formateur = profil;

  const { url, user } = useContext(AppContext);

  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [selectedProg, setSelectedProg] = useState({ activate: false });
  const [promoSelected, setPromoSelected] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(null);
  const [selectedRetards, setSelectedRetards] = useState(null);
  const [selectedAbsences, setSelectedAbsences] = useState(null);
  const [selectedAppreciations, setSelectedAppreciations] = useState(null);
  const [modifAbs, setModifAbs] = useState(null);
  const [modifRtd, setModifRtd] = useState(null);
  const [actif, setActif] = useState(true);

  const delAbsence = (iri, message = null) => {
    setLoaded(false);
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      setLoaded(true);
      if (res === 204) {
        setMsg({
          txt: message || "la suppression s'est bien déroulé",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const contextValue = {
    formateur,
    selectedProg,
    selectedNotes,
    selectedRetards,
    selectedAbsences,
    selectedAppreciations,
    modifAbs,
    promoSelected,
    setSelectedProg,
    setSelectedNotes,
    setSelectedRetards,
    setSelectedAbsences,
    setSelectedAppreciations,
    setModifAbs,
    setModifRtd,
    setPromoSelected,
    delAbsence,
  };

  const Content = () => {
    let promos = actif
      ? formateur.promos.filter((p) => p.actif)
      : formateur.promos;
    return formateur.user.sites.length === 1 ? (
      <ListPromosFormateur
        promos={promos}
        formateurId={formateur["@id"]}
        setModalPromo={setSelectedProg}
      />
    ) : (
      formateur.user.sites.sort(triByName).map((site, i) => {
        let temp = promos.filter((promo) => promo.site.id === site.id);
        return (
          <div key={i}>
            <h3 className="m-3">{site.name}</h3>
            <ListPromosFormateur
              promos={temp}
              siteId={site.id}
              formateurId={formateur["@id"]}
              setModalPromo={setSelectedProg}
            />
          </div>
        );
      })
    );
  };

  return (
    <div className="mt-4">
      <PageFormateurContext.Provider value={contextValue}>
        <ModalDev />
        <EditProgression
          selected={selectedProg}
          currentFormateur={formateur}
          promos={
            actif ? formateur.promos.filter((p) => p.actif) : formateur.promos
          }
        />
        <EditNotes selected={selectedNotes} />
        <EditCommentMat selected={selectedAppreciations} />
        {
          /* Filtre pour ne pas afficher la saisie des absences pour le site de bruz */
          user.sites.filter((s) => s.id === 16).length === 0 && (
            <>
              <EditAbsences />
              <ModifAbsence />
            </>
          ) /* Filtre pour ne pas afficher la saisie des absences pour le site de bruz */
        }
        <EditRetard />
        <ModifRetard rtdSelected={modifRtd} />
        <h2 className="m-0">Mes promos</h2>
        {user.role <= 3 ? (
          <div className="form-check mt-2">
            <input
              type="checkbox"
              className="form-check-input"
              id="chActif"
              checked={actif || false}
              onChange={(e) => setActif(!actif)}
            />
            <label className="form-check-label" htmlFor="chActif">
              <strong>Promos actives uniquement</strong>
            </label>
          </div>
        ) : null}
        <hr className="m-4" />
        {msg !== null ? (
          <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
        ) : null}
        {loaded && formateur !== null ? <Content /> : <Spinner />}
      </PageFormateurContext.Provider>
    </div>
  );
};

export default PageFormateur;
